// random color name: https://www.fantasynamegenerators.com
const colors = {
  darkBlue: '#1A212A',

  absoluteWhite: '#FFFFFF',
  semiWhite: '#F2F2F2',
  azgardWhite: '#F4F5F6',
  seltisWhite: 'rgba(255,255,255,0.2)',

  poisonGreen: '#47B355',
  primaryGreen: '#09CD5F',

  mainOrange: '#FF8A01',
  razorOrange: '#F7941D',
  navritYellow: '#F0D61C',

  dark: '#101317',
  absoluteBlack: '#000000',
  primaryDark: '#121212',
  phamudBlack: '#000000A3',
  yolunsDark: 'rgba(0, 0, 0, 0.8)',
  primaryBlack: '#292935',
  neutralGrey: '#8D9DA8',
  errorRed: '#FF4E64',
  primaryRed: '#FF2B2B',
  poisonYellow: '#B6EB1E',
  oceanWater: '#0BC3B8',
  lightBlue: '#33CFFF',

  primaryBlue: '#0643D1',
  secondaryBlue: '#061D58',
  tertiaryBlue: '#0456BD',
  pacificBlue: '#05327D',
  acridsBlue: '#0030FF',
  buldursBlue: '#0439A0',
  groxBlue: '#042B89',
  heladBlue: '#7BCFED',
  pisanBlue: '#178FFF',
  vornulBlue: '#0749F4',
  theludBlue: '#306AFF',
  estilBLue: '#789EFF',
  thulisBlue: '#0851F6',
  phisBlue: '#074BF8',

  primaryGrey: '#687083',
  secondaryGrey: '#484d55',
  tertiaryGrey: '#63717B',
  semiGrey: 'rgba(141, 157, 168, 0.50)',
  semiDarkBg: 'rgba(0, 0, 0, 0.5)',
  opacityGrey: 'rgba(255, 255, 255, 0.70)',
  lightGrey: '#CBCBCB',
  darkGrayishBlue: '#62707B',
  avertaGrey: '#E2E2E2',
  kubiGrey: '#E2E3E3',
  gruntGrey: '#B2B2B2',
  bruxGrey: '#778899',
  pumpurGrey: '#707070',
  nelziGrey: '#E9E9E9',
  algrotGrey: '#D2CFCF',
  olphieGrey: '#545454',
  plumbumGrey: 'rgba(104, 112, 131, 0.2)',
  olmoilGrey: '#CACACA',

  keidWhite: 'rgba(255, 255, 255, 0.5)',
  okinsGrey: 'rgba(55, 61, 69, 0.08)',
  colgraGrey: 'rgba(0, 0, 0, 0.10)',

  piccoloGreen: '#409E4C',
  ghimliGreen: '#49B356',

  primaryGradient: 'linear-gradient(90deg, #0CD664 43.75%, #1A212A 81.4%)',
  secondaryGradient: 'linear-gradient(90deg, #0CD664 15.59%, #1A212A 81.4%)',
  tertiaryGradient: 'linear-gradient(90deg, #0CD664 43.75%, #1A212A 81.4%)',
  quarterlyGradient: 'linear-gradient(90deg, #1A212B 0%, #136740 100%)',

  greenProgressGradient: 'linear-gradient(180deg, #47B355 0%, #358B40 100%)',
  redProgressGradient: 'linear-gradient(180deg, #FF4E64 0%, #BF3547 100%)',
  successGradient: 'linear-gradient(95deg, #32BB71 15.3%, #2A9D8F 113.45%)',

  blueGradient: 'linear-gradient(90deg, #042D66 0%, #064CFF 100%)',
  goldGradient: 'linear-gradient(180deg, #F3F71D 0%, #F7941D 100%)',
  darkBlueGradient: 'linear-gradient(94deg, #001C44 0%, #073374 100%)',
  greyGradient:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), #F7F7F7)',
  zorkGradient: 'linear-gradient(90deg, #042D66 0%, #064CFF 100%)',
  gulzoisGradient: 'linear-gradient(90deg, #042D66 0%, #064CFF 100%)',
  oceanGradient: 'linear-gradient(93.93deg, #2D82B2 -6.52%, #329ABB 108.61%)',
  seaGradient: 'linear-gradient(180deg, #0085FF 0%, #0176E2 100%)',
  hikoidGradient: 'linear-gradient(144.52deg, #063ABD 0%, #064CFF 103.48%)',
  algrotGradient: 'linear-gradient(0deg, #063ABD 0%, #064CFF 100%)',
  kudsGradient: 'linear-gradient(0deg, #064CFF 0%, #042D66 100%)',
  cradGradient: 'linear-gradient(0deg, #06389E 0%, #064CFF 100%)',

  greenGonakGradient: 'linear-gradient(180deg, #13E42E 0%, #0CA620 100%)',
};

export default colors;
