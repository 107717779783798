import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks/index';
import { II18NState, SET_LANG } from '@/store/slices/i18n.slice';

import { enabledLanguages } from '@/i18n/resourses';

export const useI18NStore = () => {
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((store) => store.i18n);

  const setLang = useCallback(
    (lng: II18NState['lang']) =>
      dispatch(SET_LANG(enabledLanguages?.includes(lng) ? lng : 'en')),
    [dispatch],
  );

  return { lang, setLang };
};
