export enum ApiTagsEnum {
  GET_ACTIVE_CONTESTS = 'GET_ACTIVE_CONTESTS',
  GET_RECENT_CONTESTS = 'GET_RECENT_CONTESTS',
  GET_ONE_CONTEST = 'GET_ONE_CONTEST',
  GET_LIVE_CONTEST = 'GET_LIVE_CONTEST',
  GET_WEEKLY_STATS = 'GET_WEEKLY_STATS',
  GET_CONTEST_LEADERBOARD = 'GET_CONTEST_LEADERBOARD',
  GET_RULES = 'GET_RULES',
  GET_USER_LEADERBOARD_POSITION = 'GET_USER_LEADERBOARD_POSITION',
  GET_USER_SELECTIONS_BY_ID = 'GET_USER_SELECTIONS_BY_ID',
  GET_PENDING_PAYMENTS = 'GET_PENDING_PAYMENTS',
  GET_ME = 'GET_ME',
  GET_PINNED_TILES = 'GET_PINNED_TILES',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_RULES_MINIPAY = 'GET_RULES_MINIPAY',
  GET_TERMS_MINIPAY = 'GET_TERMS_MINIPAY',
  GET_POLICY_MINIPAY = 'GET_POLICY_MINIPAY',
  GET_BINGO_CARDS = 'GET_BINGO_CARDS',
  GET_TMP_BINGO_CARDS = 'GET_TMP_BINGO_CARDS',
  GET_BINGO_RULES = 'GET_BINGO_RULES',
}
