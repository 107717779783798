import React, { useMemo } from 'react';

import { Grid, Box, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useLobbyStore } from '@/store/hooks/useLobbyStore.hook';

import { RoutesEnum } from '@/enums/routes.enum';

import { LobbyTabType } from '@/types/domain';

import { tKeys } from '@/i18n/resourses';

export type NavLinkType = {
  label: string;
  to: RoutesEnum;
  selected: boolean;
  tab?: LobbyTabType;
};
const Navigation: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { activeTab, setActiveTab } = useLobbyStore();
  const navLinks = useMemo<NavLinkType[]>(
    () => [
      {
        label: t(tKeys.active),
        to: RoutesEnum.home,
        selected: activeTab === 'active' && pathname === RoutesEnum.home,
        tab: 'active',
      },
      {
        label: t(tKeys.upcoming),
        to: RoutesEnum.home,
        selected: activeTab === 'upcoming' && pathname === RoutesEnum.home,
        tab: 'upcoming',
      },
      {
        label: t(tKeys.recent),
        to: RoutesEnum.recent,
        selected: pathname === RoutesEnum.recent,
      },
    ],
    [activeTab, pathname, t],
  );

  const handleLinkClick = (item: NavLinkType) => {
    navigate(item.to);
    if (item.tab) {
      setActiveTab(item.tab);
    }
  };

  return (
    <Grid
      backgroundColor={theme.colors.absoluteWhite}
      borderRadius='8px'
      gap='4px'
      mx='md'
      my='16px'
      p='2px'
      templateColumns={`repeat(${navLinks.length}, 1fr)`}
    >
      {navLinks.map((item) => (
        <Box
          backgroundColor={
            item.selected ? theme.colors.primaryBlue : 'transparent'
          }
          borderRadius='6px'
          color={
            item.selected
              ? theme.colors.absoluteWhite
              : theme.colors.primaryGrey
          }
          cursor='pointer'
          fontSize='14px'
          fontWeight='700'
          key={item.to}
          lineHeight='24px'
          onClick={() => handleLinkClick(item)}
          py='8px'
          textAlign='center'
        >
          {item.label}
        </Box>
      ))}
    </Grid>
  );
};

export default Navigation;
