import { ApiErrorCodesEnum } from '@/enums/apiErrorCodes.enum';

const translation = {
  superWhale: 'SuperWhale',
  rules: 'قواعد',
  gamesFinished: 'الألعاب المكتملة',
  inPlay: 'قيد التشغيل',
  makeYourPredict: 'قم بإجراء تنبؤاتك',
  play: 'العب',
  recent: 'الأخيرة',
  entered: 'دخل',
  entryFee: 'رسوم الدخول',
  jackpot: 'JACKPOT',
  correct: 'صحيح',
  incorrect: 'خطأ',
  currentWinnings: 'المكاسب الحالية',
  paytable: 'جدول الأجور',
  win: 'فوز',
  lose: 'خسارة',
  paytableHint:
    'إذا فاز العديد من المستخدمين ، سيتم تقسيم مبلغ جائزة الجائزة الكبرى بالتساوي',
  live: 'مباشر',
  final: 'النهائي',
  entry: 'الدخول',
  entries: 'الإدخالات',
  day: 'يوم',
  days: 'أيام',
  hour: 'ساعة',
  hours: 'ساعات',
  viewPicks: 'عرض الاختيارات',
  viewResults: 'عرض النتائج',
  editPicks: 'تعديل الاختيارات',
  playNow: 'العب الآن',
  picksHasBeenSaved: 'تم حفظ اختياراتك. يمكنك تعديل اختياراتك قبل يوم المباراة',
  picksSaved: 'الاختيارات المحفوظة',
  errorSubmit: 'تقديم خطأ',
  picksDeadline: 'الموعد النهائي للاختيار',
  picked: 'تم اختياره',
  saveMyPicks: 'احفظ اختياراتي',
  success: 'نجاح',
  error: 'خطأ',
  pending: 'قيد الانتظار ',
  confirm: 'تأكيد',
  entryFeeReceived: 'تم استلام رسوم الدخول',
  feeSuccessTransfered: 'تم تحويل رسوم الدخول بنجاح من محفظتك',
  rememberEditSelections:
    'تذكر أنه يمكنك تعديل اختياراتك بقدر ما تريد حتى بدء الجولة',
  continueEntryFee: 'هل تريد الاستمرار مع رسوم الدخول؟',
  continue: 'تابع',
  yes: 'نعم',
  close: 'أغلق',
  makeMatchesPredictions: 'قم بإجراء توقعاتك 1x2 للمباريات العشرة المدرجة',
  minute: 'Minute',
  minutes: 'Minutes',
  second: 'Second',
  seconds: 'Seconds',

  [ApiErrorCodesEnum.user_blocked]: 'User blocked',

  prizePool: 'PRIZE POOL',
  rank: 'Rank',
  points: 'Points',
  championsLeague: 'CHAMPIONS LEAGUE',
  winnings: 'Winnings',
  seeTiebreakerRules: 'See Tiebreaker rules for prize split per row',
  superTen: 'SUPER {{count}}',
  hintToWinJackpot:
    'HINT: To have a chance at the jackpot your total points must be minimum {{amount}}pts',
  resultsFromRecent:
    'Results from recent contests you participated in will be shown here!',
  player: 'Player',
  prize: 'Prize',
  pts: 'Pts',
  leaderboard: 'Leaderboard',
  paytableNav: 'Paytable',
  picks: 'Picks',
  top: 'Top',
  exclusiveOffer: 'EXCLUSIVE OFFER',
  superSideBet: 'Superwhale side bet?',
  bonus3Xmultiplier:
    '3x multiplier bonus applied to any of your winnings not including the jackpot prize',
  optInFee: '{{count}} opt-in fee',
  optInForTON: 'Opt-in for {{count}} {{currency}}',
  noThanks: 'No thanks',
  timeOfEntry: 'Time of entry',

  sideBetUnavailable: 'Sorry, Superwhale side bet is unavailable at this time',
  goAllToWinJackpot: 'Go {{amount}} for chance to win the Jackpot!',
  percentOfPool: '% of Pool',
  totalEntries: 'Total Entries',
  totalPrizePool: 'Total Prize Pool',

  goldenTicket: 'Superwhale Golden Ticket?',
  applied3xBonus: '3x multiplier bonus applied to any of your winnings',
  includingJackpot: 'not including the jackpot prize',
  admin: 'Entry fee',
  prizePool2: 'Prize Pool',
  jackpot2: 'Jackpot',
  tonMinPOT: '{{currency}} min. Pot',
  paymentPending: 'Payment pending',
  waitingConfEntryFee:
    'Your entry fee payment stays with {{wallet}}. We are waiting for confirm.',
  waitingConfSideBet:
    'Your Side Bet payment stays with {{wallet}}. We are waiting for confirm.',
  cancel: 'Cancel',
  paymentCanceled: 'Payment canceled',
  smthWentWrong: 'Something went wrong',
  disconnect: 'Disconnect',
  myProfile: 'My Profile',
  tgAccountRequired: 'Telegram account required for winning payouts',
  transactions: 'Transactions',
  copyAddress: 'Copy Address',
  league: 'League',
  date: 'Date',
  accept: 'Accept',
  askPermissionForWallet:
    'Superwhale is asking for your permission to sign-in with your address',
  insufficientWalletFunds: 'Insufficient Wallet Funds',
  youDontHaveEnoughFunds:
    'You do not have enough funds in your wallet balance to participate in this contest. Please top up your wallet or select a different contest',
  entryWasNotSuccess: 'Entry was not successful',
  sorryEntrySubmitNotCompleted:
    'Sorry, your entry submission was not completed.\n Don’t worry no funds were withdrawn from your balance.\n Please try again.\n If the problem persists, please get in touch with us.',
  sideBetWasNotSuccess: 'Side bet was not successful',
  sorrySideBetSubmitNotCompleted:
    'Sorry, your side bet submission was not completed.\n Don’t worry no funds were withdrawn from your balance.\n Please try again.\n If the problem persists, please get in touch with us.',
  allWinningsArePaid: 'All winnings are paid to your @whale account!',
  copied: 'Copied',
  selectPaymentMethod: 'Select payment method',
  termsConditions: 'Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
  active: 'Active',
  upcoming: 'Upcoming',
  multiEntry: 'MULTI-ENTRY',
};
export default translation;
