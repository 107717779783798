import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks/index';
import { ILobbyState, SET_ACTIVE_TAB } from '@/store/slices/lobby.slice';

export const useLobbyStore = () => {
  const dispatch = useAppDispatch();
  const { activeTab } = useAppSelector((store) => store.lobby);

  const setActiveTab = useCallback(
    (data: ILobbyState['activeTab']) => dispatch(SET_ACTIVE_TAB(data)),
    [dispatch],
  );

  return { activeTab, setActiveTab };
};
