import { PaymentMethodEnum } from '@/enums/paymentMethod.enum';

const config = window.config;

if (!config) {
  window.config = {
    REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    REACT_APP_TEAM_LOGO_BASE_URL: process.env.REACT_APP_TEAM_LOGO_BASE_URL,
    REACT_APP_RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
    REACT_APP_TEST_USERS: process.env.REACT_APP_TEST_USERS,
    REACT_APP_TG_BOT_NAME: process.env.REACT_APP_TG_BOT_NAME,
    REACT_APP_TG_BOT_ID: process.env.REACT_APP_TG_BOT_ID,
    REACT_APP_RECIPIENT_WALLET_ADDRESS:
      process.env.REACT_APP_RECIPIENT_WALLET_ADDRESS,
    REACT_APP_NETWORK_CHAIN: process.env.REACT_APP_NETWORK_CHAIN,
    REACT_APP_CUSD_TOKEN_ADDRESS: process.env.REACT_APP_CUSD_TOKEN_ADDRESS,
  };
}

export const API_BASE_URL = window.config.REACT_APP_API_BASE_URL as string;
export const TEAM_LOGO_BASE_URL = window.config
  .REACT_APP_TEAM_LOGO_BASE_URL as string;

export const RECAPTCHA_KEY = window.config.REACT_APP_RECAPTCHA_KEY as string;
export const TEST_USERS = window.config.REACT_APP_TEST_USERS?.split(',') || [];
export const TG_BOT_NAME = window.config.REACT_APP_TG_BOT_NAME as string;
export const TG_BOT_ID = window.config.REACT_APP_TG_BOT_ID as string;
export const RECIPIENT_WALLET_ADDRESS = window.config
  .REACT_APP_RECIPIENT_WALLET_ADDRESS as string;

export const NETWORK_CHAIN = window.config.REACT_APP_NETWORK_CHAIN as
  | 'testnet'
  | 'mainnet';
export const CUSD_TOKEN_ADDRESS = window.config
  .REACT_APP_CUSD_TOKEN_ADDRESS as string;

export const IS_MINI_PAY = () => window?.ethereum?.isMiniPay;

export const DEFAULT_PAYMENT_METHOD = IS_MINI_PAY()
  ? PaymentMethodEnum.minipay
  : PaymentMethodEnum.wallet;

export const TMP_CARDS_LIMIT = 10;
