import React, { useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import Navigation from '@/components/Navigation';

import { RoutesEnum } from '@/enums/routes.enum';

import { useUser } from '@/hooks/useUser.hook';

import { addIdToRoute } from '@/routes/util';

import { IS_MINI_PAY } from '@/utils/constants.util';

const Layout: React.FC = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  useUser();

  const isPageWithNav = useMemo(
    () => [RoutesEnum.home, RoutesEnum.recent].includes(pathname as RoutesEnum),
    [pathname],
  );
  const isHeaderHidden = useMemo(
    () =>
      [RoutesEnum.paymentSuccess, RoutesEnum.paymentError].includes(
        pathname as RoutesEnum,
      ),
    [pathname],
  );

  const isPageWithoutFooter = useMemo(
    () =>
      [
        RoutesEnum.rules,
        RoutesEnum.termsConditions,
        RoutesEnum.privacyPolicy,
        addIdToRoute(RoutesEnum.play, id),
      ].includes(pathname as RoutesEnum),
    [id, pathname],
  );

  return (
    <Box minH='100vh' position='relative'>
      {!isHeaderHidden && <Header />}

      <Box
        as='main'
        pb={IS_MINI_PAY() && !isPageWithoutFooter ? '80px' : '0px'}
        pt={isHeaderHidden ? '0' : '62px'}
      >
        {isPageWithNav && <Navigation />}
        <Outlet />
      </Box>

      {IS_MINI_PAY() && !isPageWithoutFooter && <Footer />}
    </Box>
  );
};

export default Layout;
