import { TArgsWithParams, TRoutesArgs } from '@/routes/types';

export function createPath(args: TRoutesArgs) {
  if (!args.hasOwnProperty('params')) return args.path;

  // (args as TArgsWithParams).params
  return Object.entries((args as any).params).reduce(
    (previousValue: string, [param, value]) =>
      previousValue.replace(`:${param}`, '' + value),
    args.path,
  );
}

export const addIdToRoute = (path: string, providedId: string | undefined) =>
  path.replace('/:id', `/${providedId ?? '/:id'}`);
