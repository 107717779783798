import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useTonWallet } from '@tonconnect/ui-react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useContestStore } from '@/store/hooks/useContestStore.hook';
import { useUserStore } from '@/store/hooks/useUserStore.hook';

import ArrowLeft from '@/assets/icons/superwhale/arrow-left.svg';
import AttentionIcon from '@/assets/icons/superwhale/attention-circle.svg';
import HelpIcon from '@/assets/icons/superwhale/circle-help-outline.svg';
import Logo from '@/assets/icons/superwhale/logo.svg';
import UserIcon from '@/assets/icons/superwhale/user-blue.svg';

import { LangSwitcher } from '@/components/LangSwitcher';

import { GameTypeEnum } from '@/enums/gameType.enum';
import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useContestUtils } from '@/hooks/useContestUtils.hook';
import { useOneContest } from '@/hooks/useOneContest.hook';
import { useUser } from '@/hooks/useUser.hook';

import { addIdToRoute } from '@/routes/util';

import { IS_MINI_PAY } from '@/utils/constants.util';
import { isInTMA } from '@/utils/tma-api.util';

import { tKeys } from '@/i18n/resourses';

const Header: React.FC = () => {
  const { id } = useParams();
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useOneContest({});

  // NOTE: crutch to clear contest data after page leave for correct page title
  const { contest: storeContest } = useContestStore();
  const { isContestFinished, isContestLive } = useContestUtils({
    contest: storeContest,
  });
  const wallet = useTonWallet();

  const [pageTitle, setPageTitle] = useState<string>('');

  const { userData } = useUser();
  const { jwt } = useUserStore();

  const clickedCardId = useMemo(
    () => searchParams.get(SearchParamsEnum.card_id),
    [searchParams],
  );

  useEffect(() => {
    const handlePageTitle = () => {
      if (
        [
          addIdToRoute(RoutesEnum.play, id),
          addIdToRoute(RoutesEnum.myCards, id),
        ].includes(pathname) &&
        storeContest &&
        storeContest?.game_type === GameTypeEnum.bingo
      )
        return 'BINGO';
      if (
        [RoutesEnum.rules, RoutesEnum.bingoRules].includes(
          pathname as RoutesEnum,
        )
      )
        return t(tKeys.rules);
      if (pathname === RoutesEnum.profile) return t(tKeys.myProfile);
      if (pathname === addIdToRoute(RoutesEnum.play, id) && isContestFinished) {
        return t(tKeys.gamesFinished);
      }
      if (pathname === addIdToRoute(RoutesEnum.play, id) && isContestLive) {
        return t(tKeys.inPlay);
      }
      if (
        pathname === addIdToRoute(RoutesEnum.play, id) &&
        storeContest &&
        storeContest?.game_type !== GameTypeEnum.bingo
      )
        return t(tKeys.makeYourPredict);
      if (pathname === addIdToRoute(RoutesEnum.leaderboard, id))
        return t(tKeys.leaderboard);
      if (pathname === addIdToRoute(RoutesEnum.paytable, id))
        return t(tKeys.paytable);
      if (pathname === RoutesEnum.termsConditions)
        return t(tKeys.termsConditions);
      if (pathname === RoutesEnum.privacyPolicy) return t(tKeys.privacyPolicy);
      return '';
    };
    setPageTitle(handlePageTitle());

    return () => {
      setPageTitle('');
    };
  }, [storeContest, id, isContestFinished, isContestLive, pathname, t]);

  useEffect(() => {
    console.log('storeContest', storeContest);
  }, [storeContest]);

  const isPageWithBackButton = useMemo(
    () =>
      [
        addIdToRoute(RoutesEnum.play, id),
        RoutesEnum.rules,
        RoutesEnum.profile,
        RoutesEnum.termsConditions,
        RoutesEnum.privacyPolicy,
        RoutesEnum.bingoRules,
        addIdToRoute(RoutesEnum.leaderboard, id),
        addIdToRoute(RoutesEnum.paytable, id),
        addIdToRoute(RoutesEnum.myCards, id),
      ].includes(pathname as RoutesEnum),
    [id, pathname],
  );

  const isProfilePage = useMemo(
    () => pathname === RoutesEnum.profile,
    [pathname],
  );
  const hasProfile = useMemo(
    () => (IS_MINI_PAY() ? !!jwt : isInTMA() || !!wallet),
    [jwt, wallet],
  );

  const handleGoBack = () => {
    if (pathname === addIdToRoute(RoutesEnum.play, id) && clickedCardId) {
      return navigate(-1);
    }
    return navigate(RoutesEnum.home);
  };

  return (
    <Box
      as='header'
      backgroundColor={theme.colors.absoluteWhite}
      left='0'
      position='absolute'
      top='0'
      w='100%'
      zIndex='997'
    >
      <Flex
        alignItems='center'
        bgColor='white'
        height='62px'
        justifyContent={isPageWithBackButton ? 'center' : 'space-between'}
        position='relative'
        px='16px'
        w='100%'
      >
        {isPageWithBackButton && (
          <IconButton
            aria-label='back'
            icon={<Image alt='go back' h='33px' src={ArrowLeft} w='33px' />}
            left='10px'
            onClick={handleGoBack}
            position='absolute'
            top='50%'
            transform='translateY(-50%)'
            zIndex='1'
          />
        )}
        {isPageWithBackButton ? (
          <>
            <Heading
              as='h2'
              color={theme.colors.primaryDark}
              fontSize='18px'
              fontWeight='700'
              textTransform='capitalize'
            >
              {pageTitle}
            </Heading>

            {storeContest?.game_type === GameTypeEnum.bingo &&
              [
                addIdToRoute(RoutesEnum.play, id),
                addIdToRoute(RoutesEnum.myCards, id),
              ].includes(pathname) && (
                <IconButton
                  aria-label='rules'
                  h='auto'
                  icon={<Image alt='help' h='28px' src={HelpIcon} w='28px' />}
                  minH='auto'
                  minW='auto'
                  onClick={() => navigate(RoutesEnum.bingoRules)}
                  position='absolute'
                  right='16px'
                  top='50%'
                  transform='translateY(-50%)'
                  zIndex='1'
                />
              )}
          </>
        ) : (
          <>
            <Flex alignItems='center' gap='8px'>
              <Image alt='' h='33px' src={Logo} w='33px' />
              <Text
                color='primaryBlue'
                fontSize='14px'
                fontWeight='700'
                variant='h2'
              >
                {t(tKeys.superWhale)}
              </Text>
            </Flex>

            <Flex
              alignItems='center'
              gap={{ base: '6px', xs: '8px' }}
              justifyContent='flex-end'
            >
              <LangSwitcher />

              {hasProfile && (
                <Box position='relative'>
                  <IconButton
                    aria-label='profile'
                    icon={<Image alt='user' h='28px' src={UserIcon} w='28px' />}
                    minW='auto'
                    onClick={() => navigate(RoutesEnum.profile)}
                  />
                  {!isInTMA() && !userData?.is_telegram_connected && (
                    <Image
                      alt=''
                      bottom='5px'
                      h='13px'
                      position='absolute'
                      right='-3px'
                      src={AttentionIcon}
                      w='13px'
                      zIndex='1'
                    />
                  )}
                </Box>
              )}

              <IconButton
                aria-label='rules'
                h='auto'
                icon={<Image alt='help' h='28px' src={HelpIcon} w='28px' />}
                minH='auto'
                minW='auto'
                onClick={() => navigate(RoutesEnum.rules)}
              />
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
