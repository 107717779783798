import React from 'react';

import { Box, Flex, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';

import { tKeys } from '@/i18n/resourses';

const Footer: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      as='footer'
      bgColor={theme.colors.semiWhite}
      borderBottom='0'
      borderColor={theme.colors.primaryBlue}
      borderLeft='0'
      borderRight='0'
      bottom='0'
      color={theme.colors.primaryDark}
      fontSize='12px'
      fontWeight='400'
      left='0'
      maxW='500px'
      mx='auto'
      p='24px 16px'
      position='absolute'
      textAlign='center'
      w='100%'
      zIndex='1'
    >
      <Flex
        alignItems='center'
        color={theme.colors.primaryGrey}
        fontSize='14px'
        fontWeight='700'
        gap='8px'
        justifyContent='space-between'
        textDecoration='underline'
      >
        <Box as={Link} to={RoutesEnum.termsConditions}>
          {t(tKeys.termsConditions)}
        </Box>
        <Box as={Link} to={RoutesEnum.privacyPolicy}>
          {t(tKeys.privacyPolicy)}
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
