import ArFlag from '@/assets/icons/countries/ar.svg';
import EnFlag from '@/assets/icons/countries/en.svg';
import EsFlag from '@/assets/icons/countries/es.svg';
import JaFlag from '@/assets/icons/countries/ja.svg';
import KoFlag from '@/assets/icons/countries/ko.svg';
import PtFlag from '@/assets/icons/countries/pt.svg';
import RuFlag from '@/assets/icons/countries/ru.svg';
import TrFlag from '@/assets/icons/countries/tr.svg';
import ZhFlag from '@/assets/icons/countries/zh.svg';

import ar from './locales/ar/ar';
import en from './locales/en/en';
import es from './locales/es/es';
import ja from './locales/ja/ja';
import ko from './locales/ko/ko';
import pt from './locales/pt/pt';
import ru from './locales/ru/ru';
import tr from './locales/tr/tr';
import zh from './locales/zh/zh';

export const resources = {
  en: { translation: en },
  ar: { translation: ar },
  es: { translation: es },
  ja: { translation: ja },
  ko: { translation: ko },
  pt: { translation: pt },
  ru: { translation: ru },
  tr: { translation: tr },
  zh: { translation: zh },
};

export const enabledLanguages = Object.keys(resources);

export const DEFAULT_LANGUAGE = en;

export type TFunc = (key: string) => string;
export type tKeysUnion = keyof typeof DEFAULT_LANGUAGE;
export type tKeysType = { [key in tKeysUnion]: tKeysUnion };
export const tKeys = Object.fromEntries(
  Object.keys(DEFAULT_LANGUAGE).map((key) => [key, key]),
) as tKeysType;

// TODO
export const countryFlagByName = (
  name: (typeof enabledLanguages)[number],
): string => {
  switch (name.toLowerCase()) {
    case 'ar':
      return ArFlag;
    case 'es':
      return EsFlag;
    case 'ja':
      return JaFlag;
    case 'ko':
      return KoFlag;
    case 'pt':
      return PtFlag;
    case 'ru':
      return RuFlag;
    case 'tr':
      return TrFlag;
    case 'zh':
      return ZhFlag;
    case 'en':
    default:
      return EnFlag;
  }
};

export const isLangExistsInEnabledLanguages = (lng: string | undefined) =>
  lng ? enabledLanguages.includes(lng) : false;

export const languagesDependOnUserLang = (lng: string | undefined) => {
  if (lng) {
    return [lng, ...enabledLanguages.filter((x) => x !== lng)];
  }
  return enabledLanguages;
};

export const langNameByAbbr: Record<string, string> = {
  ar: 'العربية',
  es: 'Español',
  ja: '日本語',
  ko: '한국어',
  pt: 'Português',
  ru: 'Русский',
  tr: 'Türkçe',
  zh: '中文',
  en: 'English',
};
