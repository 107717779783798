import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import ReactDOM from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import i18n from '@/i18n';
import { setupStore } from '@/store';
import theme from '@/theme';

import './index.css';
import Fonts from '@/assets/fonts';

import { RECAPTCHA_KEY } from '@/utils/constants.util';

import App from './App';
import reportWebVitals from './reportWebVitals';

export const store = setupStore();
// !IMPORTANT: change base url depends on what env you need to test (prod/dev)
const manifestBaseUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://low6-sb-superten-dev.netlify.app'
    : window.location.origin;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
          <TonConnectUIProvider
            manifestUrl={`${manifestBaseUrl}/tonconnect-manifest.json`}
            uiPreferences={{ theme: THEME.LIGHT }}
          >
            <Fonts />
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </TonConnectUIProvider>
        </GoogleReCaptchaProvider>
      </ChakraProvider>
    </I18nextProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
