import { useEffect, useMemo } from 'react';

import { useGetMeQuery } from '@/services/api.service';
import { useAppDispatch } from '@/store/hooks';
import { useI18NStore } from '@/store/hooks/useI18NStore.hook';
import { useUserStore } from '@/store/hooks/useUserStore.hook';

export const useUser = () => {
  const { setUser } = useUserStore();
  const { setLang, lang } = useI18NStore();
  const { jwt } = useUserStore();
  const {
    data,
    isSuccess: isUserSuccess,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
    refetch: refetchUser,
  } = useGetMeQuery({ jwt }, { skip: !jwt });

  const userData = useMemo(() => data?.user, [data?.user]);

  useEffect(() => {
    if (userData) {
      setUser(userData);
      setLang(userData?.lang || lang);
    }
    //eslint-disable-next-line
  }, [userData, setUser]);

  return {
    userData,
    isUserFetching,
    isUserLoading,
    isUserSuccess,
    refetchUser,
  };
};
