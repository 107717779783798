import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlayContestType, SelectionsType } from '@/types/domain';

export interface ContestStateType {
  selections: Partial<SelectionsType>[];
  selectedPick: Partial<SelectionsType> | null;
  contest: PlayContestType | undefined;
}

const initialState = {
  selections: [],
  selectedPick: null,
  contest: undefined,
} as ContestStateType;

const contestSlice = createSlice({
  name: 'contest',
  initialState,
  reducers: {
    SET_SELECTIONS(
      state,
      action: PayloadAction<ContestStateType['selections']>,
    ) {
      state.selections = action.payload;
    },
    SET_SELECTED_PICK(
      state,
      action: PayloadAction<ContestStateType['selectedPick']>,
    ) {
      state.selectedPick = action.payload;
    },
    SET_CONTEST(state, action: PayloadAction<ContestStateType['contest']>) {
      state.contest = action.payload;
    },
    SET_SELECTION(
      state,
      action: PayloadAction<Pick<SelectionsType, 'match_id' | 'team_id'>>,
    ) {
      const { match_id, team_id } = action.payload;

      const foundSelection = state.selections?.find(
        (x) => x.match_id === match_id && x.team_id === team_id,
      );
      if (foundSelection) {
        state.selections = state.selections.filter(
          (x) => x.match_id !== foundSelection?.match_id,
        );
        return;
      } else {
        state.selectedPick = { match_id, team_id };
        const foundMatchSelection = state.selections.find(
          (x) => x.match_id === match_id,
        );
        if (foundMatchSelection) {
          state.selections = state.selections?.map((x) =>
            x.match_id === match_id ? { ...x, match_id, team_id } : x,
          );
          return;
        }
        state.selections = [...state.selections, { match_id, team_id }];
        return;
      }
    },
    SET_BONUS_SELECTION(state, action: PayloadAction<Partial<SelectionsType>>) {
      const { is_bonus, match_id } = action.payload;
      const foundMatchSelection = state.selections.find(
        (x) => x.match_id === match_id,
      );

      const foundBonusSelection = state.selections.find((x) => x.is_bonus);

      if (foundBonusSelection) {
        if (foundMatchSelection && foundBonusSelection?.match_id === match_id) {
          state.selections = state.selections.map((x) =>
            x.match_id === foundMatchSelection?.match_id
              ? { ...x, is_bonus }
              : x,
          );
          return;
        } else if (
          foundMatchSelection &&
          foundBonusSelection?.match_id !== match_id
        ) {
          state.selections = state.selections.map((x) =>
            x.match_id === foundMatchSelection?.match_id
              ? { ...x, is_bonus }
              : { ...x, is_bonus: false },
          );
          return;
        } else {
          return;
        }
      } else {
        if (foundMatchSelection) {
          state.selections = state.selections.map((x) =>
            x.match_id === foundMatchSelection?.match_id
              ? { ...x, is_bonus }
              : { ...x, is_bonus: false },
          );
        }
        return;
      }
    },
  },
});

export default contestSlice.reducer;
export const {
  SET_SELECTION,
  SET_SELECTED_PICK,
  SET_SELECTIONS,
  SET_BONUS_SELECTION,
  SET_CONTEST,
} = contestSlice.actions;
