import React from 'react';

import {
  Button,
  ModalBody,
  ModalFooter,
  Image,
  Text,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import LogoBig from '@/assets/icons/superwhale/logo-big.svg';

import { useAuthContext } from '@/contexts/auth.context';

import { BaseModal } from '@/elements/BaseModal';

import { ModalProps } from '@/types/domain';

import { tKeys } from '@/i18n/resourses';

interface WalletAddressSignInModalProps extends ModalProps {}
const WalletAddressSignInModal: React.FC<WalletAddressSignInModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { walletAddressMiniPay, refreshSignMessage } = useAuthContext();

  const handleAccept = () => {
    refreshSignMessage();
    onClose();
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <ModalBody pb='0' pt='16px'>
        <Image alt='SuperWhale' h='80px' mx='auto' src={LogoBig} w='80px' />
        <Text
          color={'darkBlue'}
          fontSize='14px'
          fontWeight='600'
          maxW='315px'
          mx='auto'
          pb='24px'
          pt='16px'
          textAlign='center'
          w='100%'
        >
          {t(tKeys.askPermissionForWallet)}
        </Text>
        <Box
          bgColor={'semiWhite'}
          borderRadius='16px'
          fontSize='12px'
          fontWeight='600'
          px='16px'
          py='8px'
          textAlign='center'
        >
          {walletAddressMiniPay}
        </Box>
      </ModalBody>
      <ModalFooter
        alignItems='center'
        flexDirection='column'
        gap='5px'
        justifyContent='center'
        pt='24px'
      >
        <Button onClick={handleAccept} variant='primary' w='100%'>
          {t(tKeys.accept)}
        </Button>

        <Button onClick={onClose} variant='secondary' w='100%'>
          {t(tKeys.cancel)}
        </Button>
      </ModalFooter>
    </BaseModal>
  );
};

export default WalletAddressSignInModal;
