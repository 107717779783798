import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks/index';
import {
  IUserState,
  SET_JWT,
  SET_TG_USER,
  SET_TON_PROOF_TOKEN,
  SET_USER,
} from '@/store/slices/user.slice';

export const useUserStore = () => {
  const dispatch = useAppDispatch();
  const { tgUser, jwt, user, tonProofToken } = useAppSelector(
    (store) => store.user,
  );

  const setTGUser = useCallback(
    (user: IUserState['tgUser']) => dispatch(SET_TG_USER(user)),
    [dispatch],
  );
  const setUser = useCallback(
    (user: IUserState['user']) => dispatch(SET_USER(user)),
    [dispatch],
  );
  const setJWT = useCallback(
    (jwt: IUserState['jwt']) => dispatch(SET_JWT(jwt)),
    [dispatch],
  );
  const setTonProofToken = useCallback(
    (data: IUserState['tonProofToken']) => dispatch(SET_TON_PROOF_TOKEN(data)),
    [dispatch],
  );

  return {
    tgUser,
    user,
    jwt,
    tonProofToken,

    setUser,
    setTGUser,
    setJWT,
    setTonProofToken,
  };
};
