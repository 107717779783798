import { useEffect, useRef, useState } from 'react';

import { Contract, ethers, formatEther } from 'ethers';
import {
  Chain,
  createPublicClient,
  createWalletClient,
  custom,
  http,
  parseEther,
} from 'viem';

import {
  useGenerateSignMessageMutation,
  useVerifySignMessageMutation,
} from '@/services/api.service';
import { useUserStore } from '@/store/hooks/useUserStore.hook';

import StableTokenABI from '@/assets/abi/cusdToken.abi.json';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useJWT } from '@/hooks/useJWT.hook';

import {
  CUSD_TOKEN_ADDRESS,
  IS_MINI_PAY,
  RECIPIENT_WALLET_ADDRESS,
} from '@/utils/constants.util';
import { neededChain } from '@/utils/data.util';

export const useMiniPayAuth = () => {
  const { setJWT } = useUserStore();
  const { jwt: jwtLS, setJWT: setJWTLS } = useJWT();
  const [address, setAddress] = useState<string | null>(null);
  const [balance, setBalance] = useState<number | null>(null);
  const publicClientRef = useRef<any>(null);
  const walletClientRef = useRef<any>(null);

  const [generateSignMessage] = useGenerateSignMessageMutation();
  const [verifySignMessage] = useVerifySignMessageMutation();

  useEffect(() => {
    if (IS_MINI_PAY()) {
      const publicClient = createPublicClient({
        chain: neededChain,
        transport: http(),
      });
      const walletClient = createWalletClient({
        transport: custom(window.ethereum),
        chain: neededChain,
      });
      publicClientRef.current = publicClient;
      walletClientRef.current = walletClient;
      getUserAddress();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (IS_MINI_PAY() && address) {
      refreshBalance();
    }
    //eslint-disable-next-line
  }, [address]);

  const getUserAddress = async () => {
    let provider = (window as any).ethereum;
    if (typeof window !== 'undefined' && provider) {
      provider = new ethers.BrowserProvider(provider);
      const signer = await provider.getSigner();
      setAddress(signer?.address);
    }
  };

  const refreshBalance = async () => {
    let provider = (window as any).ethereum;
    if (typeof provider !== 'undefined' && address) {
      provider = new ethers.BrowserProvider(provider);
      const signer = await provider.getSigner();
      const contract = new Contract(
        CUSD_TOKEN_ADDRESS,
        StableTokenABI.abi,
        signer,
      );
      const userBalance = await contract.balanceOf(address);
      const balanceInEth = formatEther(userBalance);
      setBalance(Number(balanceInEth));
    }
  };

  const refreshSignMessage = () => {
    const provider = (window as any).ethereum;

    if (typeof provider !== 'undefined') {
      const ethersProvider = new ethers.BrowserProvider(provider);
      ethersProvider
        .getSigner()
        .then((signer: any) => {
          //alert(JSON.stringify(signer));
          generateSignMessage()
            .unwrap()
            .then((message: any) => {
              //alert(JSON.stringify(message));
              signer
                .signMessage(message.payload)
                .then((signature: any) => {
                  //alert(JSON.stringify(signature));
                  verifySignMessage({ message: message.payload, signature })
                    .unwrap()
                    .then((result: { walletAddress: string; jwt: string }) => {
                      setJWTLS(result?.jwt);
                      setJWT(result?.jwt);
                      //alert(JSON.stringify(result));
                    });
                })
                .catch((error: any) => {
                  //alert(JSON.stringify(error));
                });
            });
        })
        .catch((error: any) => {
          //alert(JSON.stringify(error));
        });
    }
  };

  const sendCUSD = async (to: string, amount: string) => {
    try {
      const [address] = await (walletClientRef.current as any).getAddresses();
      const amountInWei = parseEther(amount);

      const tx = await (walletClientRef.current as any).writeContract({
        address: CUSD_TOKEN_ADDRESS,
        abi: StableTokenABI.abi,
        functionName: 'transfer',
        account: address,
        args: [to, amountInWei],
      });

      const receipt = await (
        publicClientRef.current as any
      ).waitForTransactionReceipt({
        hash: tx,
      });

      return { transaction_id: tx, isSuccess: !!receipt };
    } catch (e) {
      console.error(e);
      return { transaction_id: '', isSuccess: false };
    }
  };

  return {
    walletAddressMiniPay: address,
    setWalletAddressMiniPay: setAddress,
    sendCUSD,
    refreshSignMessage,
    balanceMiniPay: balance,
    refreshBalance,
  };
};
