import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import i18n from '@/i18n';

export interface II18NState {
  lang: string;
}

const initialState = {
  lang: 'en',
} as II18NState;

const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    SET_LANG(state, action: PayloadAction<string>) {
      state.lang = action.payload;
      i18n.changeLanguage(action.payload);
    },
  },
});

export default i18nSlice.reducer;
export const { SET_LANG } = i18nSlice.actions;
