import { Chain } from 'viem';
import { celoAlfajores, celo } from 'viem/chains';

import ArsenalIcon from '@/assets/icons/arsenal.svg';
import CUSD_currency from '@/assets/icons/currencies/cusd.webp';
import TON_currency from '@/assets/icons/currencies/ton.svg';
import BronzeMedal from '@/assets/icons/superwhale/bronze-medal.png';
import CricketIcon from '@/assets/icons/superwhale/cricket.svg';
import FootballIcon from '@/assets/icons/superwhale/football-ball.svg';
import GoldenMedal from '@/assets/icons/superwhale/golden-medal.png';
import SilverMedal from '@/assets/icons/superwhale/silver-medal.png';

import { CurrencyEnum } from '@/enums/currency.enum';
import { GameTypeEnum } from '@/enums/gameType.enum';
import { MatchTypeEnum } from '@/enums/matchType.enum';
import { MatchVariantEnum } from '@/enums/matchVariant.enum';
import { SportTypeEnum } from '@/enums/sportType.enum';
import { TransactionWalletsEnum } from '@/enums/transactionWallets.enum';

import { NETWORK_CHAIN } from '@/utils/constants.util';

import {
  ContestLeagueType,
  MatchType,
  OptionStatusType,
  PlayContestType,
  PrizeBreakdownType,
  SelectionsType,
} from '@/types/domain';

import { tKeys } from '@/i18n/resourses';

export type PickemMockType = {
  id: number;
  questions: PickemQuestionType[];
  selections: PickemSelectionType[];
};
export type PickemQuestionType = {
  title: string;
  question_id: number;
  options: PickemOptionType[];
};
export type PickemOptionType = {
  option_id: number;
  title: string;
  icon: string;
  odds: string;
  is_correct: boolean;
};
export type PickemSelectionType = {
  option_id: number;
  points: number;
  question_id: number;
  selection_is_correct: boolean;
};
export const pickemMock: PickemMockType = {
  id: 1,
  questions: [
    {
      title: 'THU, SEP 28 • 1:00PM GMT',
      question_id: 1,
      options: [
        {
          option_id: 1,
          title: 'Arsenal',
          icon: ArsenalIcon,
          odds: '-120',
          is_correct: true,
        },
        {
          option_id: 2,
          title: 'Arsenal',
          icon: ArsenalIcon,
          odds: '+120',
          is_correct: false,
        },
      ],
    },
  ],
  selections: [
    {
      option_id: 1,
      points: 0,
      question_id: 1,
      selection_is_correct: false,
    },
  ],
};

export const historyAccordionItems = [
  {
    id: 1,
    title: 'THU, SEP 28 • 1:00PM GMT',
    selection: {
      icon: ArsenalIcon,
      teamName: 'Arsenal',
      count: '2 - 1',
      points: '+120',
      isWon: true,
    },
  },
  {
    id: 2,
    title: 'THU, SEP 28 • 1:00PM GMT',
    selection: {
      icon: ArsenalIcon,
      teamName: 'Arsenal',
      count: '2 - 1',
      points: '+120',
      isWon: false,
    },
  },
];

export const FAQAccordionItems = [
  {
    id: 1,
    title: 'Question 1',
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse! Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse! ',
  },
  {
    id: 2,
    title: 'Question 2',
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse! Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse! ',
  },
  {
    id: 3,
    title: 'Question 3',
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse! Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse! ',
  },
];

export const TCAcccordionItems = [
  {
    id: 1,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse! Sit, eligendi at. Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse!',
  },
  {
    id: 2,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse! Sit, eligendi at. Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse!',
  },
  {
    id: 3,
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse! Sit, eligendi at. Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum, minima, eligendi temporibus rerum amet ab eaque ipsam reprehenderit nesciunt atque hic necessitatibus soluta excepturi odio quasi esse!',
  },
];

export const bgColorsByStatuses: Record<OptionStatusType, string> = {
  selection: '#042D66',
  incorrect: '#FF4E64',
  correct: '#49B356',
  none: '#FFFFFF',
  locked: '#042D66',
};
export const textColorsByStatuses: Record<OptionStatusType, string> = {
  selection: '#000000',
  incorrect: '#1A212A',
  correct: '#1A212A',
  none: '#000000',
  locked: '#62707B',
};

export const teamNameColorsByStatuses: Record<OptionStatusType, string> = {
  selection: '#000000',
  incorrect: '#000000',
  correct: '#000000',
  none: '#000000',
  locked: '#62707B',
};

export const badgeBgColorByStatuses: Record<OptionStatusType, string> = {
  selection: '#F4F5F6',
  incorrect: '#FF2B2B',
  correct: '#49B356',
  none: '#F4F5F6',
  locked: '#F4F5F6',
};
export const badgeTextColorByStatuses: Record<OptionStatusType, string> = {
  selection: '#63717B',
  incorrect: '#FFFFFF',
  correct: '#FFFFFF',
  none: '#63717B',
  locked: '#63717B',
};

export function nFormatter(num: number, digits: number | undefined = 1) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export const entryOrEntries = (num: number | null | undefined) =>
  num === 1 ? tKeys.entry : tKeys.entries;

export const dayOrDays = (num: number | null | undefined) =>
  num === 1 ? tKeys.day : tKeys.days;

export const hourOrHours = (num: number | null | undefined) =>
  num === 1 ? tKeys.hour : tKeys.hours;

export const minuteOrMinutes = (num: number | null | undefined) =>
  num === 1 ? tKeys.minute : tKeys.minutes;

export const secondOrSeconds = (num: number | null | undefined) =>
  num === 1 ? tKeys.second : tKeys.seconds;

export const getCurrencyImagePath = (currency: CurrencyEnum | undefined) => {
  switch (currency) {
    case CurrencyEnum.cUSD:
      return CUSD_currency;
    case CurrencyEnum.TON:
    default:
      return TON_currency;
  }
};

export const formatTwoDecimalPlaces = (num: number) => num.toFixed(2);

export const numberToUSLocaleFormat = (num: number) =>
  num.toLocaleString('en-US');

export const appLangFormat = (lang: string | undefined) =>
  lang?.split('-')?.[0] || 'en';

export const convertOddsToPts = (odds: string | undefined | null) =>
  (Number(odds || 0) * 100).toFixed();

export const oddsToPtsText = (odds: string | undefined | null) =>
  `${convertOddsToPts(odds)}pts`;

export const isAwayTeamInSelections = (
  team_id: number | undefined,
  match: MatchType | undefined,
) => team_id === match?.away_team_id;
export const isHomeTeamInSelections = (
  team_id: number | undefined,
  match: MatchType | undefined,
) => team_id === match?.home_team_id;
export const isDrawInSelections = (team_id: number | undefined) =>
  team_id === 0;

export const sumAllValidSelectedPts = (
  selections: Partial<SelectionsType>[],
  matches: MatchType[],
) => {
  return selections?.reduce((acc, x) => {
    const foundMatch = matches?.find((y) => y.match_id === x.match_id);
    const isAwayTeam = isAwayTeamInSelections(x.team_id, foundMatch);
    const isHomeTeam = isHomeTeamInSelections(x.team_id, foundMatch);
    const isDraw = isDrawInSelections(x.team_id);
    const awayTeamScore = Number(foundMatch?.away_team_score);
    const homeTeamScore = Number(foundMatch?.home_team_score);
    const isNotYetScored = awayTeamScore === 0 && homeTeamScore === 0;

    if (foundMatch?.status !== MatchTypeEnum.LIVE) {
      if (
        (isAwayTeam && isNotYetScored) ||
        (isAwayTeam && awayTeamScore > homeTeamScore)
      ) {
        const pts = Number(
          convertOddsToPts(
            isExist(x.away_team_fixed_odds)
              ? x.away_team_fixed_odds
              : foundMatch?.away_team_odds,
          ),
        );
        return (acc += x.is_bonus ? pts * 2 : pts);
      }
      if (
        (isHomeTeam && isNotYetScored) ||
        (isHomeTeam && awayTeamScore < homeTeamScore)
      ) {
        const pts = Number(
          convertOddsToPts(
            isExist(x.home_team_fixed_odds)
              ? x.home_team_fixed_odds
              : foundMatch?.home_team_odds,
          ),
        );
        return (acc += x.is_bonus ? pts * 2 : pts);
      }
      if (
        (isDraw && isNotYetScored) ||
        (isDraw && awayTeamScore === homeTeamScore)
      ) {
        const pts = Number(
          convertOddsToPts(
            isExist(x.draw_fixed_odds)
              ? x.draw_fixed_odds
              : foundMatch?.draw_odds,
          ),
        );
        return (acc += x.is_bonus ? pts * 2 : pts);
      }
    }
    return acc;
  }, 0);
};

export const sumAllSelectedPts = (
  selections: Partial<SelectionsType>[],
  matches: MatchType[],
) => {
  return selections?.reduce((acc, x) => {
    const foundMatch = matches?.find((y) => y.match_id === x.match_id);
    const isAwayTeam = isAwayTeamInSelections(x.team_id, foundMatch);
    const isHomeTeam = isHomeTeamInSelections(x.team_id, foundMatch);
    const isDraw = isDrawInSelections(x.team_id);

    if (isAwayTeam) {
      const pts = Number(
        convertOddsToPts(
          isExist(x.away_team_fixed_odds)
            ? x.away_team_fixed_odds
            : foundMatch?.away_team_odds,
        ),
      );
      return (acc += x?.is_bonus ? pts * 2 : pts);
    }
    if (isHomeTeam) {
      const pts = Number(
        convertOddsToPts(
          isExist(x.home_team_fixed_odds)
            ? x.home_team_fixed_odds
            : foundMatch?.home_team_odds,
        ),
      );
      return (acc += x?.is_bonus ? pts * 2 : pts);
    }
    if (isDraw) {
      const pts = Number(
        convertOddsToPts(
          isExist(x.draw_fixed_odds)
            ? x.draw_fixed_odds
            : foundMatch?.draw_odds,
        ),
      );
      return (acc += x?.is_bonus ? pts * 2 : pts);
    }
    return acc;
  }, 0);
};

export const getOptionStatusByTeamId = ({
  team_id,
  isEnabled,
  match,
  foundSelection,
  initialSelections,
  game_type,
}: {
  team_id: number;
  isEnabled: boolean;
  match: MatchType | undefined;
  foundSelection: Partial<SelectionsType> | undefined;
  initialSelections: SelectionsType[];
  game_type: GameTypeEnum | undefined;
}) => {
  const isAwayTeam = isAwayTeamInSelections(team_id, match);
  const isHomeTeam = isHomeTeamInSelections(team_id, match);
  const isDraw = isDrawInSelections(team_id);
  const awayTeamScore = Number(match?.away_team_score);
  const homeTeamScore = Number(match?.home_team_score);

  const isOptionInInitSelections = initialSelections?.some(
    (x) => x.match_id === match?.match_id && x.team_id === team_id,
  );

  if (isEnabled) {
    if (game_type === GameTypeEnum.odds_multiplier) {
      if (isOptionInInitSelections) {
        return 'locked';
      } else if (foundSelection && !isOptionInInitSelections) {
        return 'selection';
      } else {
        return 'none';
      }
    } else {
      if (foundSelection) {
        return 'selection';
      } else {
        return 'none';
      }
    }
  } else {
    if (foundSelection) {
      if (match?.status === MatchTypeEnum.LIVE) {
        return 'locked';
      }
      if (isAwayTeam) {
        if (awayTeamScore > homeTeamScore) {
          return 'correct';
        } else {
          return 'incorrect';
        }
      } else if (isHomeTeam) {
        if (homeTeamScore > awayTeamScore) {
          return 'correct';
        } else {
          return 'incorrect';
        }
      } else if (isDraw) {
        if (homeTeamScore === awayTeamScore) {
          return 'correct';
        } else {
          return 'incorrect';
        }
      } else {
        return 'locked';
      }
    } else {
      return 'none';
    }
  }
};

export const getLeaderboardOptionStatusByTeamId = ({
  team_id,
  isEnabled,
  match,
  foundSelection,
}: {
  team_id: number;
  isEnabled: boolean;
  match: MatchType | undefined;
  foundSelection: Partial<SelectionsType> | undefined;
}) => {
  const isAwayTeam = isAwayTeamInSelections(team_id, match);
  const isHomeTeam = isHomeTeamInSelections(team_id, match);
  const isDraw = isDrawInSelections(team_id);
  const awayTeamScore = Number(match?.away_team_score);
  const homeTeamScore = Number(match?.home_team_score);

  if (isEnabled) {
    if (foundSelection) {
      return 'selection';
    } else {
      return 'none';
    }
  } else {
    if (foundSelection) {
      if (match?.status === MatchTypeEnum.LIVE) {
        return 'locked';
      }
      if (isAwayTeam) {
        if (awayTeamScore > homeTeamScore) {
          return 'correct';
        } else {
          return 'incorrect';
        }
      } else if (isHomeTeam) {
        if (homeTeamScore > awayTeamScore) {
          return 'correct';
        } else {
          return 'incorrect';
        }
      } else if (isDraw) {
        if (homeTeamScore === awayTeamScore) {
          return 'correct';
        } else {
          return 'incorrect';
        }
      } else {
        return 'locked';
      }
    } else {
      return 'none';
    }
  }
};

export const ptsOfSelectedTeam = ({
  isAway,
  isHome,
  match,
  foundMatchSelection,
}: {
  isAway: boolean;
  isHome: boolean;
  match: MatchType | undefined;
  foundMatchSelection: Partial<SelectionsType> | undefined;
}) => {
  if (isAway)
    return convertOddsToPts(
      isExist(foundMatchSelection?.away_team_fixed_odds)
        ? foundMatchSelection?.away_team_fixed_odds
        : match?.away_team_odds,
    );
  if (isHome)
    return convertOddsToPts(
      isExist(foundMatchSelection?.home_team_fixed_odds)
        ? foundMatchSelection?.home_team_fixed_odds
        : match?.home_team_odds,
    );
  return convertOddsToPts(
    isExist(foundMatchSelection?.draw_fixed_odds)
      ? foundMatchSelection?.draw_fixed_odds
      : match?.draw_odds,
  );
};

export const calcContestAnswers = (contest: PlayContestType | undefined) => {
  let correctCount = 0;
  let incorrectCount = 0;
  contest?.matches?.map((x) => {
    const foundS = contest?.selections?.find((y) => y.match_id === x.match_id);

    const awayTeamScore = Number(x.away_team_score);
    const homeTeamScore = Number(x.home_team_score);

    if (foundS && x.status === MatchTypeEnum.FINISHED) {
      const isAwayTeamSelected = isAwayTeamInSelections(foundS?.team_id, x);
      const isHomeTeamSelected = isHomeTeamInSelections(foundS?.team_id, x);
      const isDrawSelected = isDrawInSelections(foundS?.team_id);

      if (
        (isAwayTeamSelected && awayTeamScore > homeTeamScore) ||
        (isHomeTeamSelected && homeTeamScore > awayTeamScore) ||
        (isDrawSelected && homeTeamScore === awayTeamScore)
      ) {
        correctCount += 1;
      } else {
        incorrectCount += 1;
      }
    }
  });
  return { correctCount, incorrectCount };
};

export const lastRankToHavePrize = (prizes_breakdown: PrizeBreakdownType[]) => {
  const arrFromLastPlace = String(
    prizes_breakdown?.[prizes_breakdown?.length - 1]?.place,
  )?.match(/\d+/g);
  return Number(arrFromLastPlace?.[arrFromLastPlace?.length - 1]) || 0;
};

export const drawMedalByRank = (rank: number) => {
  switch (rank) {
    case 1:
      return GoldenMedal;
    case 2:
      return SilverMedal;
    case 3:
      return BronzeMedal;
    default:
      return undefined;
  }
};

export const calcPrizePool = ({
  entry_fee,
  admin_fee,
  entry_fee_commission,
}: {
  entry_fee: number | undefined;
  admin_fee: number | undefined;
  entry_fee_commission: number | undefined;
}) => Number(entry_fee) - Number(admin_fee) - Number(entry_fee_commission);

export const isExist = <T>(val: T) => val !== null && val !== undefined;

export const textByWalletType: Record<TransactionWalletsEnum, string> = {
  [TransactionWalletsEnum.CryptoBot]: 'CryptoBot',
  [TransactionWalletsEnum.Wallet]: 'Wallet',
  [TransactionWalletsEnum.Hub88]: 'Hub88',
  [TransactionWalletsEnum.CryptoChill]: 'CryptoChill',
};

export const txnData = (
  address: string,
  amount: string | number,
  validUntil?: number,
) => ({
  validUntil: validUntil || Math.floor(Date.now() / 1000) + 60, // 60 sec
  messages: [{ address, amount: tonsToNanoTons(amount) }],
});

export const tonsToNanoTons = (ton: string | number) =>
  String(Number(ton) * Math.pow(10, 9));

export const walletAddrShortFormat = (addr: string) =>
  `${addr.slice(0, 4)}...${addr.slice(-4)}`;

export const roundToFirstNonZeroDecimal = (number: number) => {
  if (number === 0 || Number.isNaN(number)) return 0;

  const absNumber = Math.abs(number);
  const decimalStr = absNumber.toString().split('.')[1];

  if (!decimalStr) return number; // If there's no decimal part, return the original number

  let firstNonZeroIndex = -1;
  for (let i = 0; i < decimalStr.length; i++) {
    if (decimalStr[i] !== '0') {
      firstNonZeroIndex = i;
      break;
    }
  }

  if (firstNonZeroIndex === -1) return number; // If all decimal digits are zero, return the original number

  const factor = Math.pow(10, firstNonZeroIndex + 1);
  const rounded = Math.round(number * factor) / factor;

  return rounded;
};

export const customNumberFormat = (num: number) => {
  const number = roundToFirstNonZeroDecimal(num);
  return Number.isInteger(number) ? number.toFixed(1) : number;
};

export const isObjEmpty = <T extends {} | undefined>(obj: T) =>
  typeof obj === 'undefined' ? true : !Object.keys(obj).length;

export const getLeagueImgByName = (
  contest_type: ContestLeagueType | string | null | undefined,
) => {
  switch (contest_type) {
    case 'IPL':
      return CricketIcon;
    case 'NBA':
    case 'EPL':
    default:
      return FootballIcon;
  }
};

export function getCookie(cookieName: string) {
  const cookies = document.cookie;
  const cookieArray = cookies.split('; ');

  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i];
    const [name, value] = cookie.split('=');

    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }

  return null;
}

export function deleteCookie(cookieName: string) {
  document.cookie = `${cookieName}=;expires=` + new Date(0).toUTCString();
}

export const optionMinHeight: Record<MatchVariantEnum, string> = {
  [MatchVariantEnum.OverUnder]: '60px',
  [MatchVariantEnum.TeamVsTeam]: '60px',
  [MatchVariantEnum.OneXTwo]: '95px',
};

export const optionJustifyContent: Record<MatchVariantEnum, string> = {
  [MatchVariantEnum.OverUnder]: 'center',
  [MatchVariantEnum.TeamVsTeam]: 'center',
  [MatchVariantEnum.OneXTwo]: 'flex-end',
};

export const questionBlockStyles = (matchVariant: MatchVariantEnum) => ({
  alignItems: 'center',
  backgroundColor: 'absoluteWhite',
  border: '2px solid',
  borderRadius: '8px',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: '600',
  px: '8px',
  py: '8px',
  minHeight: optionMinHeight[matchVariant],
  justifyContent: optionJustifyContent[matchVariant],
  gap: '6px',
});

export const calcHomeTeamOdds = ({
  match,
  foundMatchSelection,
}: {
  match: MatchType;
  foundMatchSelection: Partial<SelectionsType> | undefined;
}) => {
  if ([MatchTypeEnum.LIVE, MatchTypeEnum.UPCOMING].includes(match.status)) {
    if (isExist(foundMatchSelection?.home_team_fixed_odds))
      return oddsToPtsText(foundMatchSelection?.home_team_fixed_odds);
    return oddsToPtsText(match.home_team_odds);
  }

  if (
    [MatchVariantEnum.TeamVsTeam, MatchVariantEnum.OverUnder].includes(
      match?.match_type,
    )
  )
    return undefined;
  return match.home_team_score;
};

export const calcAwayTeamOdds = ({
  match,
  foundMatchSelection,
}: {
  match: MatchType;
  foundMatchSelection: Partial<SelectionsType> | undefined;
}) => {
  if ([MatchTypeEnum.LIVE, MatchTypeEnum.UPCOMING].includes(match.status)) {
    if (isExist(foundMatchSelection?.away_team_fixed_odds))
      return oddsToPtsText(foundMatchSelection?.away_team_fixed_odds);
    return oddsToPtsText(match.away_team_odds);
  }

  if (
    [MatchVariantEnum.TeamVsTeam, MatchVariantEnum.OverUnder].includes(
      match?.match_type,
    )
  )
    return undefined;
  return match.away_team_score;
};
export const calcDrawOdds = ({
  match,
  foundMatchSelection,
}: {
  match: MatchType;
  foundMatchSelection: Partial<SelectionsType> | undefined;
}) => {
  if ([MatchTypeEnum.LIVE, MatchTypeEnum.UPCOMING].includes(match.status)) {
    if (isExist(foundMatchSelection?.draw_fixed_odds))
      return oddsToPtsText(foundMatchSelection?.draw_fixed_odds);
    return oddsToPtsText(match.draw_odds);
  }
  return '';
};

export const oneXTwoOrFootball = (match: MatchType) =>
  match?.sport === SportTypeEnum.custom
    ? match?.match_type === MatchVariantEnum.OneXTwo
    : match?.sport === SportTypeEnum.football;

export const cannotPay = <T>(userBalance: number | null, amountToPay: T) =>
  Number(userBalance) < Number(amountToPay);

export const neededChain = (
  NETWORK_CHAIN === 'testnet' ? celoAlfajores : celo
) as Chain;

export const getBrowserInfo = () => {
  const nAgt = navigator.userAgent;
  let browserName = 'Unknown';
  let fullVersion = 'Unknown';
  let majorVersion: string | number = 'Unknown';
  let nameOffset, verOffset, ix;

  // In Chrome iOS, the true version is after "CriOS"
  if ((verOffset = nAgt.indexOf('CriOS')) != -1) {
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 6);
  }
  // In Firefox iOS, the true version is after "FxiOS"
  else if ((verOffset = nAgt.indexOf('FxiOS')) != -1) {
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 6);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // Default case for other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = 'Unknown';
    }
  }

  // Trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) != -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(' ')) != -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt(fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = 'Unknown';
    majorVersion = 'Unknown';
  }

  alert(
    'Browser name  = ' +
      browserName +
      '\n' +
      'Full version  = ' +
      fullVersion +
      '\n' +
      'Major version = ' +
      majorVersion +
      '\n' +
      'navigator.appName = ' +
      navigator.appName +
      '\n' +
      'navigator.userAgent = ' +
      navigator.userAgent +
      '\n',
  );
};
