import { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useGetOneContestQuery } from '@/services/api.service';
import { useContestStore } from '@/store/hooks/useContestStore.hook';
import { useUserStore } from '@/store/hooks/useUserStore.hook';

export const useOneContest = ({
  pollingInterval = 0,
  refetchOnMountOrArgChange = false,
}: {
  pollingInterval?: number;
  refetchOnMountOrArgChange?: boolean;
}) => {
  const { id } = useParams();
  const { jwt } = useUserStore();
  const { setContest } = useContestStore();
  const {
    data: contestData,
    isFetching: isContestFetching,
    isLoading: isContestLoading,
    refetch,
  } = useGetOneContestQuery(
    { id: id as string, jwt },
    { skip: !id || !jwt, refetchOnMountOrArgChange, pollingInterval },
  );

  const contest = useMemo(() => contestData?.contest, [contestData]);
  const initialSelections = useMemo(
    () => contestData?.contest?.selections || [],
    [contestData],
  );

  useEffect(() => {
    if (contest) {
      setContest(contest);
    }
    return () => {
      setContest(undefined);
    };
  }, [contest]);

  return {
    contest,
    isContestFetching,
    isContestLoading,
    initialSelections,
    refetch,
  };
};
