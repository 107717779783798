export enum RoutesEnum {
  home = '/',
  splash = '/splash',
  prizes = '/prizes',
  termsConditions = '/terms-conditions',
  faq = '/faq',
  history = '/history',
  myPick = '/my-pick',
  play = '/play/:id',
  playConfirm = '/play/:id/confirm',
  weeklyStats = '/weekly-stats/:id',
  leaderboard = '/leaderboard/:id',
  sso = '/sso',
  notValidRequest = '/not-valid-request',
  rules = '/rules',
  recent = '/recent',
  paymentSuccess = '/payment-success',
  paymentError = '/payment-error',
  paytable = '/paytable/:id',
  profile = '/profile',
  privacyPolicy = '/privacy-policy',
  myCards = '/my-cards/:id',
  bingoRules = '/bingo-rules',
}
