import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  header: {
    color: 'darkBlue',
    textAlign: 'center',
    pt: '40px',
    lineHeight: '120%',
    pb: '24px',
    px: '16px',
    fontSize: '20px',
  },
  body: {
    pt: '0',
    textAlign: 'center',
    color: 'darkBlue',
    fontWeight: '700',
    fontSize: '16px',
    px: '16px',
  },
  footer: {
    justifyContent: 'center',
    pt: '30px',
    pb: '24px',
    px: '16px',
  },
  overlay: {
    bg: 'rgba(0, 0, 0, 0.40)',
    backdropFilter: 'blur(8px)',
  },
  dialog: {
    borderRadius: '24px',
    bg: 'absoluteWhite',
    mx: '10px',
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
