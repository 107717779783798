import React, { createContext, useEffect, useMemo, useState } from 'react';

import { useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { useUserStore } from '@/store/hooks/useUserStore.hook';

import WalletAddressSignInModal from '@/components/Modals/WalletAddressSignInModal';

import { PageLoader } from '@/elements/PageLoader';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';
import { useMiniPayAuth } from '@/hooks/useMiniPayAuth.hook';
import { useTONProofAuth } from '@/hooks/useTONProofAuth.hook';
import { useTelegramAuth } from '@/hooks/useTelegramAuth.hook';

import { IS_MINI_PAY } from '@/utils/constants.util';
import { isInTMA } from '@/utils/tma-api.util';

type AuthContextType = {
  walletAddressMiniPay: string | null;
  sendCUSD(
    to: string,
    amount: string,
  ): Promise<{ transaction_id: string; isSuccess: boolean }>;
  setWalletAddressMiniPay: React.Dispatch<React.SetStateAction<string | null>>;
  balanceMiniPay: number | null;
  refreshSignMessage(): void;
  onWalletSignInOpen(): void;
  refreshBalance(): void;
};

const AuthContext = createContext<AuthContextType>({
  walletAddressMiniPay: null,
  sendCUSD: async () => await { transaction_id: '', isSuccess: false },
  balanceMiniPay: null,
  setWalletAddressMiniPay: () => ({}),
  refreshSignMessage: () => ({}),
  onWalletSignInOpen: () => ({}),
  refreshBalance: () => ({}),
});

const AuthContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(isInTMA());
  const { jwt, setJWT } = useUserStore();
  const { value: jwtLS } = useLocalStorage<string>(StorageKeysEnum.jwt);

  const {
    isOpen: isWalletSignInOpen,
    onOpen: onWalletSignInOpen,
    onClose: onWalletSignInClose,
  } = useDisclosure();

  const { isLoading: isTgAuthLoading } = useTelegramAuth();
  useTONProofAuth();
  const {
    walletAddressMiniPay,
    sendCUSD,
    setWalletAddressMiniPay,
    refreshSignMessage,
    balanceMiniPay,
    refreshBalance,
  } = useMiniPayAuth();

  const isAllLoading = useMemo(
    () => (isInTMA() ? isTgAuthLoading : false),
    [isTgAuthLoading],
  );

  useEffect(() => {
    if (!isInTMA() && jwtLS && !jwt) {
      setJWT(jwtLS);
    }
  }, [jwt, jwtLS, setJWT]);

  useEffect(() => {
    if (jwt) {
      setIsLoading(false);
    }
  }, [jwt]);

  return (
    <AuthContext.Provider
      value={{
        walletAddressMiniPay,
        sendCUSD,
        setWalletAddressMiniPay,
        refreshSignMessage,
        onWalletSignInOpen,
        balanceMiniPay,
        refreshBalance,
      }}
    >
      {isLoading || isAllLoading ? <PageLoader /> : children}

      <WalletAddressSignInModal
        isOpen={isWalletSignInOpen}
        onClose={onWalletSignInClose}
      />
    </AuthContext.Provider>
  );
};

function useAuthContext(): AuthContextType {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }
  return context;
}

export { AuthContextProvider, useAuthContext };
