import React, { PropsWithChildren } from 'react';

import {
  Flex,
  Image,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useTheme,
  Modal,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Logo from '@/assets/icons/superwhale/logo.svg';

import { ModalProps } from '@/types/domain';

import { tKeys } from '@/i18n/resourses';

export const BaseModal: React.FC<ModalProps & PropsWithChildren> = ({
  isOpen,
  onClose,
  children,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          bgColor={theme.colors.semiWhite}
          borderRadius='24px 24px 0 0'
          py='16px'
        >
          <Flex alignItems='center' gap='8px' justifyContent='center'>
            <Image alt='logo' h='33px' src={Logo} w='33px' />
            <Text
              color={theme.colors.pacificBlue}
              fontSize='18px'
              fontWeight='700'
            >
              {t(tKeys.superWhale)}
            </Text>
          </Flex>
        </ModalHeader>

        {children}
      </ModalContent>
    </Modal>
  );
};
