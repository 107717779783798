import React from 'react';

import { Global } from '@emotion/react';

import AvertaBold from '@/assets/fonts/AvertaStd-Bold.ttf';
import AvertaExtraBold from '@/assets/fonts/AvertaStd-ExtraBold.ttf';
import AvertaRegular from '@/assets/fonts/AvertaStd-Regular.ttf';
import AvertaSemibold from '@/assets/fonts/AvertaStd-Semibold.ttf';
import CutmarkExtraBold from '@/assets/fonts/Cutmark-XBold.ttf';

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Averta';
        font-style: normal;
        font-weight: 400;
        src: url(${AvertaRegular}) format("truetype");
      }
      /* latin */
      @font-face {
        font-family: 'Averta';
        font-style: normal;
        font-weight: 600;
        src: url(${AvertaSemibold}) format("truetype");
      }
      /* latin */
      @font-face {
        font-family: 'Averta';
        font-style: normal;
        font-weight: 700;
        src: url(${AvertaBold}) format("truetype");
      }
      /* latin */
      @font-face {
        font-family: 'Averta';
        font-style: normal;
        font-weight: 800;
        src: url(${AvertaExtraBold}) format("truetype");
      }

      /* latin */
      @font-face {
        font-family: 'Cutmark';
        font-style: normal;
        font-weight: 800;
        src: url(${CutmarkExtraBold}) format("truetype");
      }
      `}
  />
);

export default Fonts;
