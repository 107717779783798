import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContestCardsType, SelectionsType, TmpBingoCard } from '@/types/domain';

export interface BingoStateType {
  currentCard: TmpBingoCard | ContestCardsType | null;
}

const initialState = {
  currentCard: null,
} as BingoStateType;

const bingoSlice = createSlice({
  name: 'bingo',
  initialState,
  reducers: {
    SET_CURRENT_CARD(
      state,
      action: PayloadAction<BingoStateType['currentCard']>,
    ) {
      state.currentCard = action.payload;
    },
  },
});

export default bingoSlice.reducer;
export const { SET_CURRENT_CARD } = bingoSlice.actions;
