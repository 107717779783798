import { ApiErrorCodesEnum } from '@/enums/apiErrorCodes.enum';

const translation = {
  superWhale: 'SuperWhale',
  rules: '规则',
  gamesFinished: '游戏结束',
  inPlay: '滚球',
  makeYourPredict: '做出您的预测',
  play: '玩',
  recent: '最近的',
  entered: '已输入',
  entryFee: '入场费',
  jackpot: '奖池',
  correct: '正确',
  incorrect: '错误',
  currentWinnings: '当前收益',
  paytable: '支付表',
  win: '赢',
  lose: '输',
  paytableHint: '如果多个用户赢得游戏，则累积奖池将平分',
  live: '直播',
  final: '结束',
  entry: '入场',
  entries: '已选次数',
  day: '天',
  days: '天数',
  hour: '小时',
  hours: '小时',
  viewPicks: '查看您的预测',
  viewResults: '查看结果',
  editPicks: '编辑您的预测',
  playNow: '现在玩',
  picksHasBeenSaved: '您的预测已保存。您可以在游戏日之前编辑预测。',
  picksSaved: '预测已保存',
  errorSubmit: '提交错误',
  picksDeadline: '选择截止时间',
  picked: '已选择',
  saveMyPicks: '保存我的预测',
  success: '成功',
  error: '错误',
  pending: '待处理',
  confirm: '确认',
  entryFeeReceived: '已收到入场费',
  feeSuccessTransfered: '入场费已成功从您的钱包转出。',
  rememberEditSelections:
    '请记住，您可以在回合开始之前尽可能多地编辑您的选择。',
  continueEntryFee: '您要继续支付入场费吗？',
  continue: '继续',
  yes: '是',
  close: '关闭',
  makeMatchesPredictions: '为列出的{{count}}场比赛做出您的1x2预测',
  minute: 'Minute',
  minutes: 'Minutes',
  second: 'Second',
  seconds: 'Seconds',

  [ApiErrorCodesEnum.user_blocked]: 'User blocked',

  prizePool: 'PRIZE POOL',
  rank: 'Rank',
  points: 'Points',
  championsLeague: 'CHAMPIONS LEAGUE',
  winnings: 'Winnings',
  seeTiebreakerRules: 'See Tiebreaker rules for prize split per row',
  superTen: 'SUPER {{count}}',
  hintToWinJackpot:
    'HINT: To have a chance at the jackpot your total points must be minimum {{amount}}pts',
  resultsFromRecent:
    'Results from recent contests you participated in will be shown here!',
  player: 'Player',
  prize: 'Prize',
  pts: 'Pts',
  leaderboard: 'Leaderboard',
  paytableNav: 'Paytable',
  picks: 'Picks',
  top: 'Top',
  exclusiveOffer: 'EXCLUSIVE OFFER',
  superSideBet: 'Superwhale side bet?',
  bonus3Xmultiplier:
    '3x multiplier bonus applied to any of your winnings not including the jackpot prize',
  optInFee: '{{count}} opt-in fee',
  optInForTON: 'Opt-in for {{count}} {{currency}}',
  noThanks: 'No thanks',
  timeOfEntry: 'Time of entry',

  sideBetUnavailable: 'Sorry, Superwhale side bet is unavailable at this time',
  goAllToWinJackpot: 'Go {{amount}} for chance to win the Jackpot!',
  percentOfPool: '% of Pool',
  totalEntries: 'Total Entries',
  totalPrizePool: 'Total Prize Pool',

  goldenTicket: 'Superwhale Golden Ticket?',
  applied3xBonus: '3x multiplier bonus applied to any of your winnings',
  includingJackpot: 'not including the jackpot prize',
  admin: 'Entry fee',
  prizePool2: 'Prize Pool',
  jackpot2: 'Jackpot',
  tonMinPOT: '{{currency}} min. Pot',
  paymentPending: 'Payment pending',
  waitingConfEntryFee:
    'Your entry fee payment stays with {{wallet}}. We are waiting for confirm.',
  waitingConfSideBet:
    'Your Side Bet payment stays with {{wallet}}. We are waiting for confirm.',
  cancel: 'Cancel',
  paymentCanceled: 'Payment canceled',
  smthWentWrong: 'Something went wrong',
  disconnect: 'Disconnect',
  myProfile: 'My Profile',
  tgAccountRequired: 'Telegram account required for winning payouts',
  transactions: 'Transactions',
  copyAddress: 'Copy Address',
  league: 'League',
  date: 'Date',
  accept: 'Accept',
  askPermissionForWallet:
    'Superwhale is asking for your permission to sign-in with your address',
  insufficientWalletFunds: 'Insufficient Wallet Funds',
  youDontHaveEnoughFunds:
    'You do not have enough funds in your wallet balance to participate in this contest. Please top up your wallet or select a different contest',
  entryWasNotSuccess: 'Entry was not successful',
  sorryEntrySubmitNotCompleted:
    'Sorry, your entry submission was not completed.\n Don’t worry no funds were withdrawn from your balance.\n Please try again.\n If the problem persists, please get in touch with us.',
  sideBetWasNotSuccess: 'Side bet was not successful',
  sorrySideBetSubmitNotCompleted:
    'Sorry, your side bet submission was not completed.\n Don’t worry no funds were withdrawn from your balance.\n Please try again.\n If the problem persists, please get in touch with us.',
  allWinningsArePaid: 'All winnings are paid to your @whale account!',
  copied: 'Copied',
  selectPaymentMethod: 'Select payment method',
  termsConditions: 'Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
  active: 'Active',
  upcoming: 'Upcoming',
  multiEntry: 'MULTI-ENTRY',
};
export default translation;
