import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LobbyTabType, UserType } from '@/types/domain';

export interface ILobbyState {
  activeTab: LobbyTabType;
}

const initialState: ILobbyState = {
  activeTab: 'upcoming',
} as ILobbyState;

const lobbySlice = createSlice({
  name: 'lobby',
  initialState,
  reducers: {
    SET_ACTIVE_TAB(state, action: PayloadAction<ILobbyState['activeTab']>) {
      state.activeTab = action.payload;
    },
  },
});

export default lobbySlice.reducer;
export const { SET_ACTIVE_TAB } = lobbySlice.actions;
