import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserType } from '@/types/domain';

export interface IUserState {
  tgUser:
    | {
        id?: number;
        is_bot: boolean;
        first_name: string;
        last_name?: string;
        username?: string;
        language_code?: string;
        photo_url?: string;
      }
    | undefined;
  user: UserType | undefined;
  jwt: string | undefined;
  tonProofToken: string | null;
}

const initialState = {
  tgUser: undefined,
  jwt: undefined,
  user: undefined,
  tonProofToken: null,
} as IUserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SET_TG_USER(state, action: PayloadAction<IUserState['tgUser']>) {
      state.tgUser = action.payload;
    },
    SET_USER(state, action: PayloadAction<IUserState['user']>) {
      state.user = action.payload;
    },
    SET_JWT(state, action: PayloadAction<IUserState['jwt']>) {
      state.jwt = action.payload;
    },
    SET_TON_PROOF_TOKEN(
      state,
      action: PayloadAction<IUserState['tonProofToken']>,
    ) {
      state.tonProofToken = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { SET_TG_USER, SET_USER, SET_JWT, SET_TON_PROOF_TOKEN } =
  userSlice.actions;
