import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { api } from '@/services/api.service';
import bingoSlice from '@/store/slices/bingo.slice';
import contestSlice from '@/store/slices/contest.slice';
import i18nSlice from '@/store/slices/i18n.slice';
import lobbySlice from '@/store/slices/lobby.slice';
import userSlice from '@/store/slices/user.slice';

const rootReducer = combineReducers({
  user: userSlice,
  i18n: i18nSlice,
  contest: contestSlice,
  lobby: lobbySlice,
  bingo: bingoSlice,
  [api.reducerPath]: api.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
