import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { TelegramAuthData } from '@telegram-auth/react';

import { IUsernameBody } from '@/providers/Username.provider';

import { ApiTagsEnum } from '@/enums/apiTags.enum';
import { ContestTenantEnum } from '@/enums/contestTenant.enum';
import { PaymentMethodEnum } from '@/enums/paymentMethod.enum';

import { baseQueryCustom, CONTEST_LEADERBOARD_LIMIT } from '@/utils/api.util';

import {
  BaseRes,
  BuyBingoCardBody,
  CheckTonProofReqBody,
  GetAllContestsRes,
  GetBingoCardsRes,
  GetContestLeaderboardReqBody,
  GetContestLeaderboardRes,
  GetContestSelectionsRes,
  GetLiveContestRes,
  GetOneContestRes,
  GetPendingPaymentsRes,
  GetPinnedTilesRes,
  GetTmpBingoCardsBody,
  GetTmpBingoCardsRes,
  GetTransactionsRes,
  GetUserLeaderboardPositionRes,
  GetUserResBody,
  GetUserSelectionsByIdRes,
  GetWeeklyStatsParams,
  GetWeeklyStatsRes,
  LoginTelegramRes,
  LoginWithUserIDType,
  MakeSelectionsBody,
  MakeSelectionsRes,
  MakeSideBetReqBody,
  MakeSideBetRes,
  VerifySignMessageBody,
  VerifySignMessageRes,
} from '@/types/domain';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: baseQueryCustom,
  endpoints: (builder) => ({
    getActiveContests: builder.query<
      GetAllContestsRes,
      { jwt: string | undefined; tenant: ContestTenantEnum }
    >({
      query: (reqBody) => {
        const { jwt, ...params } = reqBody;
        return {
          url: '/contests',
          params,
        };
      },
      providesTags: [ApiTagsEnum.GET_ACTIVE_CONTESTS],
    }),
    getRecentContests: builder.query<
      GetAllContestsRes,
      { jwt: string | undefined }
    >({
      query: () => '/recent-contests',
      providesTags: [ApiTagsEnum.GET_RECENT_CONTESTS],
    }),
    getOneContest: builder.query<
      GetOneContestRes,
      { id: string; jwt: string | undefined }
    >({
      query: ({ id }) => `/contests/${id}`,
      providesTags: [ApiTagsEnum.GET_ONE_CONTEST],
    }),
    getLiveContest: builder.query<GetLiveContestRes, void>({
      query: () => '/live-contest',
      providesTags: [ApiTagsEnum.GET_LIVE_CONTEST],
    }),
    getWeeklyStats: builder.query<GetWeeklyStatsRes, GetWeeklyStatsParams>({
      query: ({ contest_week_id, week_id }) =>
        `/contests/${contest_week_id}/weeks/${week_id}/stats`,
      providesTags: [ApiTagsEnum.GET_WEEKLY_STATS],
    }),
    getContestLeaderboard: builder.query<
      GetContestLeaderboardRes,
      GetContestLeaderboardReqBody
    >({
      query: ({ id, jwt, ...params }) => ({
        url: `/contests/${id}/leaderboard`,
        params: params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const last7CharactersInJWT = queryArgs.jwt?.slice(-7);
        return `${endpointName}("jwt": ${last7CharactersInJWT})`;
      },
      merge: (currentCache, newItems) => {
        currentCache.leaderboard.push(...newItems.leaderboard);
        currentCache.isLastPage =
          newItems.leaderboard.length < CONTEST_LEADERBOARD_LIMIT;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      },
      keepUnusedDataFor: 0,
      providesTags: [ApiTagsEnum.GET_CONTEST_LEADERBOARD],
    }),
    getUserLeaderboardPosition: builder.query<
      GetUserLeaderboardPositionRes,
      { contest_id: string }
    >({
      query: ({ contest_id }) => `/contests/${contest_id}/leaderboard-position`,
      providesTags: [ApiTagsEnum.GET_USER_LEADERBOARD_POSITION],
    }),
    getUserSelectionsById: builder.query<
      GetUserSelectionsByIdRes,
      { contest_id: string; user_id: string | number }
    >({
      query: ({ contest_id, user_id }) =>
        `/contests/${contest_id}/user-selections/${user_id}`,
      providesTags: [ApiTagsEnum.GET_USER_SELECTIONS_BY_ID],
    }),
    makeSelections: builder.mutation<MakeSelectionsRes, MakeSelectionsBody>({
      query: ({ contest_id, ...body }) => ({
        url: `/contests/${contest_id}/selections`,
        method: 'POST',
        body,
      }),
    }),
    postContestEnter: builder.mutation<BaseRes, any>({
      query: ({ contest_id, ...body }) => ({
        url: `/contests/${contest_id}/enter`,
        method: 'POST',
        body,
      }),
    }),
    loginWithUserID: builder.mutation<{ token: string }, LoginWithUserIDType>({
      query: (body) => ({
        url: '/sso/login',
        method: 'POST',
        body,
      }),
    }),
    getMe: builder.query<GetUserResBody, { jwt: string | undefined }>({
      query: () => '/me',
      providesTags: [ApiTagsEnum.GET_ME],
    }),
    updateMe: builder.mutation<BaseRes, IUsernameBody>({
      query: (body) => ({ url: '/me', method: 'PUT', body }),
    }),
    getRules: builder.query<{ content: string }, { lang: string }>({
      query: ({ lang }) => `page/rules?lang=${lang}`,
      providesTags: [ApiTagsEnum.GET_RULES],
    }),
    getContestSelections: builder.query<
      GetContestSelectionsRes,
      { contest_id: number | string }
    >({
      query: ({ contest_id }) => `/contests/${contest_id}/selections`,
    }),
    makeSideBet: builder.mutation<MakeSideBetRes, MakeSideBetReqBody>({
      query: ({ contest_id, ...body }) => ({
        url: `contests/${contest_id}/side-bet`,
        method: 'POST',
        body,
      }),
    }),
    checkSideBet: builder.query<
      { has_side_bet: boolean },
      { contest_id: number | string }
    >({
      query: ({ contest_id }) => `contests/${contest_id}/check-side-bet`,
    }),
    cancelPayment: builder.mutation<BaseRes, { contest_id: string | number }>({
      query: ({ contest_id }) => ({
        url: `contests/${contest_id}/cancel-payment`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagsEnum.GET_PENDING_PAYMENTS],
    }),
    cancelSideBet: builder.mutation<BaseRes, { contest_id: string | number }>({
      query: ({ contest_id }) => ({
        url: `contests/${contest_id}/cancel-sidebet-payment`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagsEnum.GET_PENDING_PAYMENTS],
    }),
    getPendingPayments: builder.query<GetPendingPaymentsRes, number | string>({
      query: (contest_id) => `contests/${contest_id}/pending-payments`,
      providesTags: [ApiTagsEnum.GET_PENDING_PAYMENTS],
    }),
    updateLang: builder.mutation<BaseRes, { language: string }>({
      query: (body) => ({
        url: 'lang',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [ApiTagsEnum.GET_ME],
    }),
    generateTONProof: builder.mutation<{ payload: string }, void>({
      query: () => ({
        url: 'ton-proof/generate-payload',
        method: 'POST',
      }),
    }),
    checkTONProof: builder.mutation<{ jwt: string }, CheckTonProofReqBody>({
      query: (body) => ({
        url: 'ton-proof/check-proof',
        method: 'POST',
        body,
      }),
    }),
    loginTelegram: builder.mutation<LoginTelegramRes, TelegramAuthData>({
      query: (body) => ({
        url: 'sso/login-telegram',
        method: 'POST',
        body,
      }),
    }),
    getPinnedTiles: builder.query<GetPinnedTilesRes, void>({
      query: () => '/pined-tiles',
      providesTags: [ApiTagsEnum.GET_PINNED_TILES],
    }),
    getTransactions: builder.query<
      GetTransactionsRes,
      { jwt: string | undefined }
    >({
      query: () => '/transactions',
      providesTags: [ApiTagsEnum.GET_TRANSACTIONS],
    }),
    generateSignMessage: builder.mutation<{ payload: string }, void>({
      query: () => ({
        url: 'auth/sign-message',
        method: 'POST',
      }),
    }),
    verifySignMessage: builder.mutation<
      VerifySignMessageRes,
      VerifySignMessageBody
    >({
      query: (body) => ({
        url: 'auth/verify-message',
        method: 'POST',
        body,
      }),
    }),
    getRulesMiniPay: builder.query<{ content: string }, { lang: string }>({
      query: ({ lang }) => `page/rules-minipay?lang=${lang}`,
      providesTags: [ApiTagsEnum.GET_RULES_MINIPAY],
    }),
    getTermsMiniPay: builder.query<{ content: string }, { lang: string }>({
      query: ({ lang }) => `page/terms-minipay?lang=${lang}`,
      providesTags: [ApiTagsEnum.GET_TERMS_MINIPAY],
    }),
    getPolicyMiniPay: builder.query<{ content: string }, { lang: string }>({
      query: ({ lang }) => `page/policy-minipay?lang=${lang}`,
      providesTags: [ApiTagsEnum.GET_POLICY_MINIPAY],
    }),
    // TODO: remove
    generateBingoCard: builder.mutation<
      any,
      { contest_id: number | undefined }
    >({
      query: ({ contest_id }) => ({
        url: `/admin/contests/${contest_id}/bingo-test-card`,
        method: 'POST',
        body: { user_id: 2 },
      }),
    }),
    buyBingoCard: builder.mutation<any, BuyBingoCardBody>({
      query: ({ contest_id, ...body }) => ({
        url: `contests/${contest_id}/bingo-card`,
        method: 'POST',
        body,
      }),
    }),
    getBingoCards: builder.query<
      GetBingoCardsRes,
      { contest_id: number | undefined }
    >({
      query: ({ contest_id }) => `contests/${contest_id}/bingo-cards`,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}({"contest_id":${queryArgs.contest_id})`;
      },
      merge: (currentCache, newItems, { arg }) => {
        currentCache.isCardsUpdated =
          newItems?.cards?.length > currentCache?.cards?.length;
        currentCache.cards = newItems?.cards;
      },
      keepUnusedDataFor: 0,
      providesTags: [ApiTagsEnum.GET_BINGO_CARDS],
    }),
    getTmpBingoCards: builder.query<GetTmpBingoCardsRes, GetTmpBingoCardsBody>({
      query: ({ contest_id, ...params }) => ({
        url: `contests/${contest_id}/bingo-tmp-cards`,
        params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}({"contest_id":${queryArgs.contest_id})`;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (Number(arg?.offset) === 0) {
          return newItems;
        }
        currentCache.cards = [
          ...currentCache.cards,
          ...newItems.cards.filter(
            (x) =>
              !currentCache.cards.some((y) => y.tmp_card_id === x.tmp_card_id),
          ),
        ];
        currentCache.isLastPage = newItems.cards.length < arg?.limit;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      },
      keepUnusedDataFor: 0,
      providesTags: [ApiTagsEnum.GET_TMP_BINGO_CARDS],
    }),
    getBingoRules: builder.query<{ content: string }, { lang: string }>({
      query: ({ lang }) => `page/bingo-rules?lang=${lang}`,
      providesTags: [ApiTagsEnum.GET_BINGO_RULES],
    }),
  }),
});

export const {
  useGetActiveContestsQuery,
  useGetRecentContestsQuery,
  useGetOneContestQuery,
  useGetLiveContestQuery,
  useMakeSelectionsMutation,
  useGetWeeklyStatsQuery,
  useGetContestLeaderboardQuery,
  usePostContestEnterMutation,
  useLoginWithUserIDMutation,
  useGetMeQuery,
  useUpdateMeMutation,
  useGetRulesQuery,

  useGetContestSelectionsQuery,
  useLazyGetContestSelectionsQuery,
  useGetUserLeaderboardPositionQuery,
  useGetUserSelectionsByIdQuery,
  useLazyGetUserSelectionsByIdQuery,

  useMakeSideBetMutation,
  useCheckSideBetQuery,
  useCancelPaymentMutation,
  useCancelSideBetMutation,
  useGetPendingPaymentsQuery,
  useUpdateLangMutation,

  useGenerateTONProofMutation,
  useCheckTONProofMutation,

  useLoginTelegramMutation,
  useGetPinnedTilesQuery,
  useGetTransactionsQuery,

  useGenerateSignMessageMutation,
  useVerifySignMessageMutation,

  useGetRulesMiniPayQuery,
  useGetTermsMiniPayQuery,
  useGetPolicyMiniPayQuery,

  useGenerateBingoCardMutation,
  useBuyBingoCardMutation,
  useGetBingoCardsQuery,
  useGetTmpBingoCardsQuery,
  useGetBingoRulesQuery,
} = api;
