import { useMemo } from 'react';

import dayjs from 'dayjs';

import { GameTypeEnum } from '@/enums/gameType.enum';
import { MatchTypeEnum } from '@/enums/matchType.enum';

import { lastRankToHavePrize } from '@/utils/data.util';
import { dateToCurrentTZ } from '@/utils/date.util';

import { PlayContestType } from '@/types/domain';

export const useContestUtils = ({
  contest,
}: {
  contest: PlayContestType | undefined;
}) => {
  const isContestLive = useMemo(
    () =>
      contest?.game_type === GameTypeEnum.bingo
        ? contest?.matches?.some((x) =>
            [MatchTypeEnum.LIVE, MatchTypeEnum.FINISHED].includes(
              x.odds_status,
            ),
          )
        : contest?.matches?.some((x) =>
            [MatchTypeEnum.LIVE, MatchTypeEnum.FINISHED].includes(x.status),
          ),
    [contest?.game_type, contest?.matches],
  );

  const isContestFinished = useMemo(
    () =>
      contest?.game_type === GameTypeEnum.bingo
        ? !!contest?.matches?.every(
            (x) => x.odds_status === MatchTypeEnum.FINISHED,
          )
        : !!contest?.matches?.every((x) => x.status === MatchTypeEnum.FINISHED),
    [contest?.game_type, contest?.matches],
  );

  const contestStatus = useMemo(() => {
    if (isContestFinished) return MatchTypeEnum.FINISHED;
    if (isContestLive) return MatchTypeEnum.LIVE;
    return MatchTypeEnum.UPCOMING;
  }, [isContestFinished, isContestLive]);

  const isContestDisabled = useMemo(
    () =>
      !!(
        contest?.start_utc &&
        dateToCurrentTZ(contest?.start_utc).isBefore(
          dateToCurrentTZ(dayjs()),
        ) &&
        (contest?.game_type === GameTypeEnum.bingo
          ? !contest?.cards?.length
          : !contest?.selections?.length)
      ),
    [contest],
  );

  const isUserInPrizeBreakdownRange = useMemo(
    () =>
      !!(
        contest?.results?.rank &&
        contest?.results?.rank <=
          lastRankToHavePrize(contest?.prizes_breakdown || [])
      ),
    [contest?.prizes_breakdown, contest?.results?.rank],
  );

  return {
    isContestLive,
    isContestFinished,
    contestStatus,
    isContestDisabled,
    isUserInPrizeBreakdownRange,
  };
};
