import React, { MouseEvent, useCallback, useMemo, useState } from 'react';

import { Box, Flex, Image, Text } from '@chakra-ui/react';
import OutsideClickHandler from 'react-outside-click-handler';

import { useUpdateLangMutation } from '@/services/api.service';
import { useI18NStore } from '@/store/hooks/useI18NStore.hook';

import DownChevron from '@/assets/icons/superwhale/down-chevron.svg';

import {
  countryFlagByName,
  enabledLanguages,
  langNameByAbbr,
  languagesDependOnUserLang,
} from '@/i18n/resourses';

export const LangSwitcher: React.FC = () => {
  const { lang, setLang } = useI18NStore();
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const [triggerUpdateLng] = useUpdateLangMutation();

  const languageOptions = useMemo(
    () =>
      languagesDependOnUserLang(lang).map(
        (item: (typeof enabledLanguages)[number]) => ({
          value: item.toLowerCase(),
          image: countryFlagByName(item),
          label: langNameByAbbr[item.toLowerCase()],
        }),
      ),
    [lang],
  );

  const onLangClick = async (e: MouseEvent<HTMLDivElement>, lng: string) => {
    try {
      e.preventDefault();
      e.stopPropagation();

      if (lng !== lang) {
        await triggerUpdateLng({ language: lng });
      }

      setLang(lng);
      setIsOpened(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpened(false)}>
      <Flex
        alignItems='center'
        bgColor='primaryBlue'
        borderRadius='50px'
        cursor='pointer'
        gap='4px'
        h='28px'
        justifyContent='center'
        onClick={() => setIsOpened((prev) => !prev)}
        p='0 12px'
        position='relative'
      >
        <Text color='absoluteWhite' fontSize='12px' fontWeight='700'>
          {langNameByAbbr[lang]}
        </Text>
        <Image alt='' h='16px' src={DownChevron} w='16px' />

        {isOpened && (
          <Flex
            bgColor='primaryBlue'
            borderRadius='14px'
            cursor='default'
            flexDirection='column'
            gap='12px'
            left='0'
            p='7.5px 8px'
            position='absolute'
            top='35px'
            w='150%'
            zIndex='1'
          >
            {languageOptions.map((item) => (
              <Flex
                alignItems='center'
                cursor='pointer'
                gap='8px'
                key={item.value}
                onClick={(e) => onLangClick(e, item.value)}
                py='6px'
              >
                <Text color='absoluteWhite' fontSize='12px' fontWeight='700'>
                  {item.label}
                </Text>
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
    </OutsideClickHandler>
  );
};
