import React, { Suspense } from 'react';

import { TelegramWebApp } from '@kloktunov/react-telegram-webapp';
import { Outlet } from 'react-router-dom';

import { AuthContextProvider } from '@/contexts/auth.context';
import { LocalStorageProvider } from '@/contexts/localStorage.context';
import { SidebarContextProvider } from '@/contexts/sidebar.context';

import { PageLoader } from '@/elements/PageLoader';

const AppLayout: React.FC = () => {
  return (
    <TelegramWebApp>
      <LocalStorageProvider>
        <AuthContextProvider>
          <SidebarContextProvider>
            <Suspense fallback={<PageLoader />}>
              <Outlet />
            </Suspense>
          </SidebarContextProvider>
        </AuthContextProvider>
      </LocalStorageProvider>
    </TelegramWebApp>
  );
};

export default AppLayout;
