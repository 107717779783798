import { lazy } from 'react';

import { RoutesEnum } from '@/enums/routes.enum';

import { IRoutes } from '@/routes/types';

const HomePage = lazy(() => import('@/pages/Home'));
const SplashPage = lazy(() => import('@/pages/Splash'));
const PrizesPage = lazy(() => import('@/pages/Prizes'));
const TermsConditionsPage = lazy(() => import('@/pages/TermsConditions'));
const FAQPage = lazy(() => import('@/pages/FAQ'));
const HistoryPage = lazy(() => import('@/pages/History'));
const MyPickPage = lazy(() => import('@/pages/MyPick'));
const PlayPickPage = lazy(() => import('@/pages/Play'));
const PlayConfirm = lazy(() => import('@/pages/PlayConfirm'));
const WeeklyStatsPage = lazy(() => import('@/pages/WeeklyStats'));
const LeaderboardPage = lazy(() => import('@/pages/Leaderboard'));
const SSOPage = lazy(() => import('@/pages/SSO'));
const NotValidRequest = lazy(() => import('@/pages/NotValidRequest'));
const RulesPage = lazy(() => import('@/pages/Rules'));
const RecentPage = lazy(() => import('@/pages/Recent'));
const PaymentSuccessPage = lazy(() => import('@/pages/PaymentSuccess'));
const PaymentErrorPage = lazy(() => import('@/pages/PaymentError'));
const PayTablePage = lazy(() => import('@/pages/PayTable'));
const ProfilePage = lazy(() => import('@/pages/Profile'));
const PrivacyPolicyPage = lazy(() => import('@/pages/PrivacyPolicy'));
const MyCardsPage = lazy(() => import('@/pages/MyCards'));
const BingoRulesPage = lazy(() => import('@/pages/BingoRules'));

export const AppRoutes: IRoutes[] = [
  {
    path: RoutesEnum.home,
    element: <HomePage />,
  },
  {
    path: RoutesEnum.splash,
    element: <SplashPage />,
  },
  {
    path: RoutesEnum.prizes,
    element: <PrizesPage />,
  },
  {
    path: RoutesEnum.faq,
    element: <FAQPage />,
  },
  {
    path: RoutesEnum.history,
    element: <HistoryPage />,
  },
  {
    path: RoutesEnum.myPick,
    element: <MyPickPage />,
  },
  {
    path: RoutesEnum.play,
    element: <PlayPickPage />,
  },
  {
    path: RoutesEnum.playConfirm,
    element: <PlayConfirm />,
  },
  {
    path: RoutesEnum.weeklyStats,
    element: <WeeklyStatsPage />,
  },
  {
    path: RoutesEnum.leaderboard,
    element: <LeaderboardPage />,
  },
  {
    path: RoutesEnum.sso,
    element: <SSOPage />,
  },
  {
    path: RoutesEnum.notValidRequest,
    element: <NotValidRequest />,
  },
  {
    path: RoutesEnum.rules,
    element: <RulesPage />,
  },
  {
    path: RoutesEnum.recent,
    element: <RecentPage />,
  },
  {
    path: RoutesEnum.paymentSuccess,
    element: <PaymentSuccessPage />,
  },
  {
    path: RoutesEnum.paymentError,
    element: <PaymentErrorPage />,
  },
  {
    path: RoutesEnum.paytable,
    element: <PayTablePage />,
  },
  {
    path: RoutesEnum.profile,
    element: <ProfilePage />,
  },
  {
    path: RoutesEnum.termsConditions,
    element: <TermsConditionsPage />,
  },
  {
    path: RoutesEnum.privacyPolicy,
    element: <PrivacyPolicyPage />,
  },
  {
    path: RoutesEnum.myCards,
    element: <MyCardsPage />,
  },
  {
    path: RoutesEnum.bingoRules,
    element: <BingoRulesPage />,
  },
];
