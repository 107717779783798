type TmaPlatform =
  | 'android'
  | 'ios'
  | 'macos'
  | 'tdesktop'
  | 'weba'
  | 'web'
  | 'unknown';

export function getWindow(): Window | undefined {
  if (typeof window !== 'undefined') {
    return window;
  }

  return undefined;
}

let initParams: Record<string, string> = {};

try {
  const locationHash = location.hash.toString();
  initParams = urlParseHashParams(locationHash);
} catch (e) {}

let tmaPlatform: TmaPlatform = 'unknown';
if (initParams?.tgWebAppPlatform) {
  tmaPlatform = (initParams.tgWebAppPlatform as TmaPlatform) ?? 'unknown';
}
if (tmaPlatform === 'unknown') {
  const window = getWindow();
  tmaPlatform = window?.Telegram?.WebApp?.platform ?? 'unknown';
}

let webAppVersion = '6.0';
if (initParams?.tgWebAppVersion) {
  webAppVersion = initParams.tgWebAppVersion;
}
if (!webAppVersion) {
  const window = getWindow();
  webAppVersion = window?.Telegram?.WebApp?.version ?? '6.0';
}

/**
 * Returns true if the app is running in TMA on the specified platform.
 * @param platforms
 */
export function isTmaPlatform(...platforms: TmaPlatform[]): boolean {
  return platforms.includes(tmaPlatform);
}

/**
 * Returns true if the app is running in TMA (Telegram Mini Apps).
 */
export function isInTMA(): boolean {
  return (
    tmaPlatform !== 'unknown' ||
    !!(getWindow() as { TelegramWebviewProxy: unknown } | undefined)
      ?.TelegramWebviewProxy
  );
}

function urlParseHashParams(locationHash: string): Record<string, string> {
  locationHash = locationHash.replace(/^#/, '');
  const params: Record<string, string> = {};
  if (!locationHash.length) {
    return params;
  }
  if (locationHash.indexOf('=') < 0 && locationHash.indexOf('?') < 0) {
    params._path = urlSafeDecode(locationHash);
    return params;
  }
  const qIndex = locationHash.indexOf('?');
  if (qIndex >= 0) {
    const pathParam = locationHash.substr(0, qIndex);
    params._path = urlSafeDecode(pathParam);
    locationHash = locationHash.substr(qIndex + 1);
  }
  const query_params = urlParseQueryString(locationHash);
  for (const k in query_params) {
    params[k] = query_params[k]!;
  }
  return params;
}

function urlSafeDecode(urlencoded: string): string {
  try {
    urlencoded = urlencoded.replace(/\+/g, '%20');
    return decodeURIComponent(urlencoded);
  } catch (e) {
    return urlencoded;
  }
}

function urlParseQueryString(
  queryString: string,
): Record<string, string | null> {
  const params: Record<string, string | null> = {};
  if (!queryString.length) {
    return params;
  }
  const queryStringParams = queryString.split('&');
  let i, param, paramName, paramValue;
  for (i = 0; i < queryStringParams.length; i++) {
    param = queryStringParams[i]!.split('=');
    paramName = urlSafeDecode(param[0]!);
    paramValue = param[1] == null ? null : urlSafeDecode(param[1]);
    params[paramName] = paramValue;
  }
  return params;
}
