import { ComponentStyleConfig, keyframes } from '@chakra-ui/react';

const defaultButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  textAlign: 'center',
  fontWeight: '700',
  fontSize: '14px',
  color: 'absoluteWhite',
  borderRadius: '8px',
  border: 'none',
  cursor: 'pointer',
  userSelect: 'none',
  outline: 'none',
  boxShadow: 'none',
  textDecoration: 'none',
};

const pulse = keyframes`
  0% {
    box-shadow: 0px 0px 0px 0px #2555d2;
  }
  60% {
    box-shadow: 0px 0px 0px 8px #2555d2;
  }
  100% {
    box-shadow: 0px 0px 0px 0px #2555d2;
  }
`;

const Button: ComponentStyleConfig = {
  baseStyle: {
    outline: 'none',
    _hover: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
    _active: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  sizes: {
    sm: {
      fontSize: '14px',
      px: '0px',
      py: '0px',
    },
    md: {
      fontSize: '14px',
      px: '0px',
      py: '0px',
    },
  },
  variants: {
    primary: {
      ...defaultButtonStyles,
      background: 'poisonGreen',
      color: 'absoluteWhite',
      _disabled: {
        background: 'tertiaryGrey',
        color: 'absoluteWhite',
        outline: 'none',
        opacity: '1',
      },
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        _disabled: {
          background: 'tertiaryGrey',
          color: 'absoluteWhite',
          outline: 'none',
          opacity: '1',
        },
      },
    },
    secondary: {
      ...defaultButtonStyles,
      background: 'transparent',
      color: 'absoluteBlack',
      border: '1px solid',
      borderColor: '#49B356',
      _disabled: {
        background: 'transparent',
        color: 'absoluteBlack',
        outline: 'none',
        opacity: '1',
      },
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        _disabled: {
          background: 'transparent',
          color: 'absoluteBlack',
          outline: 'none',
        },
      },
    },
    tertiary: {
      ...defaultButtonStyles,
      background: 'darkBlue',
      color: 'absoluteWhite',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      _disabled: {
        background: 'darkBlue',
        color: 'absoluteWhite',
        outline: 'none',
        opacity: '1',
      },
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        _disabled: {
          background: 'darkBlue',
          color: 'absoluteWhite',
          outline: 'none',
        },
      },
    },
    white: {
      ...defaultButtonStyles,
      background: 'absoluteWhite',
      color: 'dark',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      _disabled: {
        background: 'absoluteWhite',
        color: 'dark',
        outline: 'none',
        opacity: '1',
      },
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        _disabled: {
          background: 'absoluteWhite',
          color: 'dark',
          outline: 'none',
        },
      },
    },
    gradientBlue: {
      ...defaultButtonStyles,
      background: 'hikoidGradient',
      color: 'absoluteWhite',
      animation: `${pulse} 2s infinite`,
      height: 'auto',
      _disabled: {
        animation: 'none',
        background: 'blueGradient',
        color: 'absoluteWhite',
        outline: 'none',
        opacity: '1',
      },
    },
    ghost: {
      ...defaultButtonStyles,
      _hover: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'none',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
      _active: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'ghost',
  },
};

export default Button;
