import { ApiErrorCodesEnum } from '@/enums/apiErrorCodes.enum';

const translation = {
  superWhale: 'SuperWhale',
  rules: '룰',
  gamesFinished: '완료한 게임',
  inPlay: '진행 중',
  makeYourPredict: '당신의 예측을 만드세요',
  play: '플레이',
  recent: '최근의',
  entered: '참가함',
  entryFee: '참가비',
  jackpot: '잭팟',
  correct: '정확한',
  incorrect: '틀림',
  currentWinnings: '현재 상금',
  paytable: '지불표',
  win: '승리',
  lose: '패배',
  paytableHint: '여러 사용자가 이기면 잭팟 상금이 동일하게 분배됩니다.',
  live: '라이브',
  final: '최종',
  entry: '참가',
  entries: '참가내역',
  day: '일',
  days: '일',
  hour: '시간',
  hours: '시간',
  viewPicks: '귀하의 선택',
  viewResults: '결과보기',
  editPicks: '선택 수정',
  playNow: '지금 플레이',
  picksHasBeenSaved:
    '귀하의 선택이 저장되었습니다. 게임 당일 전에 선택을 수정할 수 있습니다.',
  picksSaved: '선택 저장되었습니다',
  errorSubmit: '오류 제출',
  picksDeadline: '선택 마감일',
  picked: '선택함',
  saveMyPicks: '내 선택 저장',
  success: '성공',
  error: '오류',
  pending: '대기중',
  confirm: '확인',
  entryFeeReceived: '참가비 결제',
  feeSuccessTransfered: '참가비가 지갑에서 성공적으로 전송되었습니다.',
  rememberEditSelections:
    '라운드가 시작될 때까지 선택을 원하는 만큼 편집할 수 있습니다.',
  continueEntryFee: '참가비를 지불하고 계속하시겠습니까?',
  continue: '계속하기',
  yes: '예',
  close: '닫기',
  makeMatchesPredictions:
    '나열된 ({{count}}) 경기에 대한 1x2 예측을 만드십시오.',
  minute: 'Minute',
  minutes: 'Minutes',
  second: 'Second',
  seconds: 'Seconds',

  [ApiErrorCodesEnum.user_blocked]: 'User blocked',

  prizePool: 'PRIZE POOL',
  rank: 'Rank',
  points: 'Points',
  championsLeague: 'CHAMPIONS LEAGUE',
  winnings: 'Winnings',
  seeTiebreakerRules: 'See Tiebreaker rules for prize split per row',
  superTen: 'SUPER {{count}}',
  hintToWinJackpot:
    'HINT: To have a chance at the jackpot your total points must be minimum {{amount}}pts',
  resultsFromRecent:
    'Results from recent contests you participated in will be shown here!',
  player: 'Player',
  prize: 'Prize',
  pts: 'Pts',
  leaderboard: 'Leaderboard',
  paytableNav: 'Paytable',
  picks: 'Picks',
  top: 'Top',
  exclusiveOffer: 'EXCLUSIVE OFFER',
  superSideBet: 'Superwhale side bet?',
  bonus3Xmultiplier:
    '3x multiplier bonus applied to any of your winnings not including the jackpot prize',
  optInFee: '{{count}} opt-in fee',
  optInForTON: 'Opt-in for {{count}} {{currency}}',
  noThanks: 'No thanks',
  timeOfEntry: 'Time of entry',

  sideBetUnavailable: 'Sorry, Superwhale side bet is unavailable at this time',
  goAllToWinJackpot: 'Go {{amount}} for chance to win the Jackpot!',
  percentOfPool: '% of Pool',
  totalEntries: 'Total Entries',
  totalPrizePool: 'Total Prize Pool',

  goldenTicket: 'Superwhale Golden Ticket?',
  applied3xBonus: '3x multiplier bonus applied to any of your winnings',
  includingJackpot: 'not including the jackpot prize',
  admin: 'Entry fee',
  prizePool2: 'Prize Pool',
  jackpot2: 'Jackpot',
  tonMinPOT: '{{currency}} min. Pot',
  paymentPending: 'Payment pending',
  waitingConfEntryFee:
    'Your entry fee payment stays with {{wallet}}. We are waiting for confirm.',
  waitingConfSideBet:
    'Your Side Bet payment stays with {{wallet}}. We are waiting for confirm.',
  cancel: 'Cancel',
  paymentCanceled: 'Payment canceled',
  smthWentWrong: 'Something went wrong',
  disconnect: 'Disconnect',
  myProfile: 'My Profile',
  tgAccountRequired: 'Telegram account required for winning payouts',
  transactions: 'Transactions',
  copyAddress: 'Copy Address',
  league: 'League',
  date: 'Date',
  accept: 'Accept',
  askPermissionForWallet:
    'Superwhale is asking for your permission to sign-in with your address',
  insufficientWalletFunds: 'Insufficient Wallet Funds',
  youDontHaveEnoughFunds:
    'You do not have enough funds in your wallet balance to participate in this contest. Please top up your wallet or select a different contest',
  entryWasNotSuccess: 'Entry was not successful',
  sorryEntrySubmitNotCompleted:
    'Sorry, your entry submission was not completed.\n Don’t worry no funds were withdrawn from your balance.\n Please try again.\n If the problem persists, please get in touch with us.',
  sideBetWasNotSuccess: 'Side bet was not successful',
  sorrySideBetSubmitNotCompleted:
    'Sorry, your side bet submission was not completed.\n Don’t worry no funds were withdrawn from your balance.\n Please try again.\n If the problem persists, please get in touch with us.',
  allWinningsArePaid: 'All winnings are paid to your @whale account!',
  copied: 'Copied',
  selectPaymentMethod: 'Select payment method',
  termsConditions: 'Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
  active: 'Active',
  upcoming: 'Upcoming',
  multiEntry: 'MULTI-ENTRY',
};
export default translation;
