import { useContext, useEffect, useRef, useState } from 'react';

import { useWebAppInitDataUnsafe } from '@kloktunov/react-telegram-webapp';
import {
  useIsConnectionRestored,
  useTonConnectUI,
  useTonWallet,
} from '@tonconnect/ui-react';

import {
  useCheckTONProofMutation,
  useGenerateTONProofMutation,
} from '@/services/api.service';
import { useUserStore } from '@/store/hooks/useUserStore.hook';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useJWT } from '@/hooks/useJWT.hook';

import { IS_MINI_PAY } from '@/utils/constants.util';
import { deleteCookie, getCookie, isObjEmpty } from '@/utils/data.util';
import { isInTMA } from '@/utils/tma-api.util';

const payloadTTLMS = 1000 * 60 * 20;

export const useTONProofAuth = () => {
  const { setJWT } = useUserStore();
  const isConnectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const interval = useRef<ReturnType<typeof setInterval> | undefined>();
  const { jwt: jwtLS, setJWT: setJWTLS, removeJWT: removeJWTLS } = useJWT();

  const [triggerGenerate] = useGenerateTONProofMutation();
  const [triggerCheck] = useCheckTONProofMutation();

  useEffect(() => {
    const handleTONProof = () => {
      if (isInTMA() || IS_MINI_PAY() || !isConnectionRestored) {
        return;
      }

      clearInterval(interval.current);

      if (!wallet) {
        removeJWTLS();
        setJWT(undefined);
        deleteCookie(StorageKeysEnum.jwt);

        const refreshPayload = async () => {
          tonConnectUI.setConnectRequestParameters({ state: 'loading' });

          const res = await triggerGenerate().unwrap();

          if (!res) {
            tonConnectUI.setConnectRequestParameters(null);
          } else {
            tonConnectUI.setConnectRequestParameters({
              state: 'ready',
              value: { tonProof: res.payload },
            });
          }
        };

        refreshPayload();
        setInterval(refreshPayload, payloadTTLMS);
        return;
      }

      if (jwtLS) {
        setJWT(jwtLS);
        document.cookie = `jwt=${jwtLS}`;
        return;
      }

      if (
        wallet.connectItems?.tonProof &&
        !('error' in wallet.connectItems.tonProof)
      ) {
        triggerCheck({ wallet })
          .unwrap()
          .then((result) => {
            if (result) {
              setJWT(result?.jwt);
              setJWTLS(result?.jwt);
              document.cookie = `jwt=${result?.jwt}`;
            } else {
              console.log('Please try another wallet');
              tonConnectUI.disconnect();
              if (!isInTMA()) {
                removeJWTLS();
                deleteCookie(StorageKeysEnum.jwt);
              }
            }
          });
      } else {
        console.log('Please try another wallet');
        tonConnectUI.disconnect();
        if (!isInTMA()) {
          removeJWTLS();
          deleteCookie(StorageKeysEnum.jwt);
        }
      }
    };
    handleTONProof();
    //eslint-disable-next-line
  }, [
    wallet,
    isConnectionRestored,
    setJWT,
    tonConnectUI,
    triggerGenerate,
    triggerCheck,
  ]);
};
