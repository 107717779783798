import React, { useEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import AppLayout from '@/AppLayout';
import { AppRoutes } from '@/routes';

import Layout from '@/components/Layout';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route element={<Layout />}>
          {AppRoutes.map((route) => (
            <Route element={route.element} key={route.path} path={route.path} />
          ))}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
