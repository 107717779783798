import React, { createContext, useEffect } from 'react';

import { useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { MediaQueriesEnum } from '@/enums/mediaQueries.enum';

type SidebarContextType = {
  onSidebarOpen(): void;
  onSidebarClose(): void;
  isSidebarOpen: boolean;
};

const SidebarContext = createContext<SidebarContextType>({
  isSidebarOpen: false,
  onSidebarOpen: () => ({}),
  onSidebarClose: () => ({}),
});

const SidebarContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isDesktop] = useMediaQuery(MediaQueriesEnum.isDesktop, { ssr: false });
  const { pathname } = useLocation();
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  useEffect(() => {
    const body = document.getElementById('body');
    if (isSidebarOpen && body) body.style.overflow = 'hidden';
    else if (!isSidebarOpen && body) body.style.overflow = 'auto';
  }, [isSidebarOpen]);

  useEffect(() => {
    onSidebarClose();
  }, [onSidebarClose, pathname]);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        onSidebarClose,
        onSidebarOpen,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

function useSidebarContext(): SidebarContextType {
  const context = React.useContext(SidebarContext);
  if (context === undefined) {
    throw new Error(
      'useSidebarContext must be used within a SidebarContextProvider',
    );
  }
  return context;
}

export { SidebarContextProvider, useSidebarContext };
