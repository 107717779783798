import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export const formatISODateForPlayPicks = (isoDate: string) =>
  dayjs(isoDate).utc().format('ddd, MMM D • h:mma [GMT]');
export const formatISODateShorter = (isoDate: string) =>
  dayjs(isoDate).utc().format('ddd, MMM D');

export const dateToCurrentTZ = (date?: Dayjs | string) => dayjs(date).tz();

export const isDateNowInWeekPeriodFromStartDate = (
  startISODate: string,
  endISODate: string | undefined,
) => {
  const startWeekDate = dayjs(startISODate);
  const endWeekDate = dayjs(endISODate);
  const currentDate = dayjs();

  return endISODate
    ? currentDate.isSameOrAfter(startWeekDate) &&
        currentDate.isSameOrBefore(endWeekDate)
    : currentDate.isSameOrAfter(startWeekDate);
};

export const isDateSameOrAfter = (date: string | Dayjs | undefined) =>
  dayjs().isSameOrAfter(dayjs(date));

export const isDateBefore = (isoDate: string) =>
  dayjs().isBefore(dayjs(isoDate));

export const countdown = (countDownDate: Dayjs, obj: { days: string }) => {
  const now = new Date(Date.now()).getTime();

  const distance = countDownDate.valueOf() - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return `${days ? days + `${obj.days}:` : ''}${hours + 'h:'}${minutes + 'm:'}${
    seconds + 's'
  }`;
};

export const formatPickEndDate = (isoDate: string) => {
  return dayjs(isoDate)
    .tz() // Convert to the desired timezone
    .format('dddd M/D/YYYY h:mm A z');
};

export const formatContestDeadline = (inputDate: string | Dayjs) =>
  dayjs(inputDate).utc().format('dddd MM/DD/YYYY HH:mm A [GMT]');

export const formatLeaderboardEntryDate = (date: Dayjs | string | undefined) =>
  dayjs(date).tz().format('ddd, MMM D h:mmA');

export const formatDateBingoCell = (date: Dayjs | string | undefined) =>
  dayjs(date).tz().format('ddd H:mm');

export const formatDateMyCard = (date: Dayjs | string | undefined) =>
  dayjs(date).tz().format('HH:mm MMM D, YYYY');
