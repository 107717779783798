import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks/index';
import {
  ContestStateType,
  SET_BONUS_SELECTION,
  SET_CONTEST,
  SET_SELECTED_PICK,
  SET_SELECTION,
  SET_SELECTIONS,
} from '@/store/slices/contest.slice';

import { SelectionsType } from '@/types/domain';

export const useContestStore = () => {
  const dispatch = useAppDispatch();
  const { selections, selectedPick, contest } = useAppSelector(
    (store) => store.contest,
  );

  const setSelection = useCallback(
    (data: Pick<SelectionsType, 'match_id' | 'team_id'>) =>
      dispatch(SET_SELECTION(data)),
    [dispatch],
  );
  const setSelections = useCallback(
    (data: ContestStateType['selections']) => dispatch(SET_SELECTIONS(data)),
    [dispatch],
  );
  const setSelectedPick = useCallback(
    (data: ContestStateType['selectedPick']) =>
      dispatch(SET_SELECTED_PICK(data)),
    [dispatch],
  );
  const setBonusSelection = useCallback(
    (data: Partial<SelectionsType>) => dispatch(SET_BONUS_SELECTION(data)),
    [dispatch],
  );
  const setContest = useCallback(
    (data: ContestStateType['contest']) => dispatch(SET_CONTEST(data)),
    [dispatch],
  );

  return {
    selections,
    selectedPick,
    contest,

    setSelections,
    setSelection,
    setSelectedPick,
    setBonusSelection,
    setContest,
  };
};
