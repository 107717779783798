import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';

import { RootState } from '@/store';

import { ContestTenantEnum } from '@/enums/contestTenant.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { API_BASE_URL, IS_MINI_PAY } from '@/utils/constants.util';
import { getCookie } from '@/utils/data.util';
import { getStorageItem } from '@/utils/localStorage.util';
import { isInTMA } from '@/utils/tma-api.util';

export const prepareHeaders = (
  headers: Headers,
  { getState }: Pick<BaseQueryApi, 'getState'>,
) => {
  const state = getState() as RootState;
  const jwt = isInTMA()
    ? state?.user?.jwt
    : getCookie(StorageKeysEnum.jwt) ||
      getStorageItem<string>(StorageKeysEnum.jwt);

  if (jwt) {
    headers.set('authorization', `Bearer ${jwt}`);
  }

  return headers;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders,
});

export const baseQueryCustom: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  // TODO: uncomment
  // if (result.error?.status === 401) {
  //   localStorage.clear();
  // }

  return result;
};

export const CONTEST_LEADERBOARD_LIMIT = 10;

export const TENANT = IS_MINI_PAY()
  ? ContestTenantEnum.MiniPay
  : ContestTenantEnum.Telegram;
